import axios from 'axios';
import host from "./host";
import ApiService from "@/services/api.service";

const API_URL =host+'/api/auth/';

class AuthService {
    login(user) {
        return axios
            .post(API_URL + 'signin', {
                email: user.email,
                password: user.password
            })
            .then(response => {
                if (response.data.accessToken) {
                    localStorage.setItem('user', JSON.stringify(response.data));
                }

                return response.data;
            });
    }

    logout() {
        localStorage.removeItem('user');
    }

    register(user) {
        return axios.post(API_URL + 'signup', user).then(response => {
            if (response.data.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response.data;
        });
    }

    async registerBusiness(user) {
        try {
            const response = await axios.post(API_URL + 'signup/business', user);

            if (response?.data?.accessToken) {
                localStorage.setItem('user', JSON.stringify(response.data));
            }
            return response?.data;
        }catch (error){
                if (error.response) {
                    ApiService.handleElephantError(error.response);
                }
        }
    }

    async loginSocial(provider, body) {

        try {
            return await axios.post(API_URL + provider, body);
        }catch (error){
            if (error.response) {
                ApiService.handleElephantError(error.response);
            }
        }
    }

    async getLinkedInToken(token){
        try {
            return await axios.post(API_URL + 'linkedIn/token', {token:token,typeOfAccount:null,company:null});
        }catch (error){
            if (error.response) {
                ApiService.handleElephantError(error.response);
            }
        }
    }
}

export default new AuthService();
