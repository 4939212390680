import { hu as vhu } from "vuetify/locale";

export const hu = {
  $vuetify: {
    ...vhu,
  },
  theme: {
    dark: "Sötét mód",
    light: "Világos mód"
  },
  refer: "Ingatlant ajánl",
  referHint2: "Hamarosan! Küldd el nekünk az ingatlanjaidat.",
  business: "Üzleti",
  welcome: "Üdvözlünk az EstateBoost-ban!",
  private: "Magánszemély",
  password: "Jelszó",
  required: "Kérjük, töltsd ki ezt a mezőt",
  personalInformation: "Személyes adatok",
  readMore: "Tovább olvasom",
  auth: {
    loginWithLinkedIn: "Bejelentkezés LinkedIn-nel",
    loginWithSocials: "Bejelentkezés közösségi médiával",
    orUserNameAndPassword: "vagy felhasználónév és jelszó",
    alreadyHaveAccount: "Már van fiókod? Jelentkezz be itt.",
    inputPassword: "Írd be a jelszót",
    repeatPassword: "Ismételd meg a jelszót",
    password: {
      requirements: {
        title: "A jelszónak az alábbi követelményeknek kell megfelelnie",
        oneNumber: "Tartalmaznia kell legalább egy számot",
        oneSpecialChar: "Tartalmaznia kell legalább egy speciális karaktert",
        oneChar: "Tartalmaznia kell legalább egy kis- vagy nagybetűt",
        length: "Legalább 8 karakter hosszúnak kell lennie"
      }
    },
    forgotPassword: "Elfelejtett jelszó?",
    createAccount: "Fiók létrehozása",
    register: {
      title: "Regisztráció",
      privacy: "Elolvastam és elfogadtam a {agb} & {privacy} feltételeket.",
      privacyError: "A regisztrációhoz el kell fogadnod a feltételeket és az adatvédelmi irányelveket.",
      business: {
        checkbox: "ÁFA-köteles vagyok",
        name: "Cég neve",
        legalForm: "Jogi forma",
        uStIdNr: "ÁFA azonosító",
        hint: "Ki esik az ÁFA hatálya alá?",
        hintText: "Németországban az éves forgalom 22.000 € felett ÁFA-fizetési kötelezettséget jelent. Ezen küszöb alatt kisvállalkozásnak minősülnek."
      },
      businessOrPrivate: "Üzleti entitásként vagy magánszemélyként regisztrálsz?",
      businessOrPrivateHint: "Az EstateBoost testreszabott funkciókat kínál üzleti és magánszemélyek számára, hogy megfeleljen a specifikus igényeidnek."
    }
  },
  points: "Pont | Pontok",
  rank: "Rangsor",
  profile: {
    share: {
      text:
        "Ez a személyes profil linked. Véletlenszerűen generált kulccsal van védve, így csak azok érhetik el, akik rendelkeznek ezzel a linkkel.\n\n    A regenerálás gombra kattintva a régi link érvénytelen lesz.",
      generate: "Újragenerálás",
      share: "Profil megosztása",
      sharetext: "Látogasd meg a profilomat az EstateBoost-ban."
    }
  },
  achievements: {
    empty: "Nincs további eredmény"
  },
  rewards: {
    unclaimed: "Igénylésre vár",
    claimed: "Igénybe vett"
  },
  dashboard: {
    your_properties: "Ingatlanaid",
    ranking: "Rangsor",
    education: "Oktatás",
    to_wallet: "A pénztárcába",
    compareValue: "Összehasonlító érték mindig a hónap 1-jén"
  },
  dailyreward: {
    button: "Napi bónusz",
    hint: "Csak naponta egyszer igényelhető.",
    claim: "Igénylés",
    thanks: "Köszönjük",
    schade: "Sajnáljuk"
  },
  time: {
    days: "Nap",
    hours: "Óra",
    minutes: "Perc",
    seconds: "Másodperc"
  },
  confirm_email: {
    text: "Az emailed még nincs megerősítve.",
    button: "Erősítsd meg most"
  },
  enum: {
    pending: "Függőben",
    gkProcess: {
      SEND_TEASER_EXPOSE: "Teaser/Expose küldése",
      AWAIT_APPROVAL: "Jóváhagyásra vár",
      ADD_PROPERTY_IN_ESTATEBOOST: "Ingatlan hozzáadása az EstateBoost-ban",
      VERIFY_INPUT: "Adatok ellenőrzése",
      CREATE_EXPOSE_DATA_ROOM: "Expose/Data Room létrehozása",
      EVALUATE_PROPERTY: "Ingatlan értékelése",
      APPROVE_EVALUATION: "Értékelés jóváhagyása",
      OPEN_DUE_DILIGENCE_PROCESS: "Due Diligence folyamat megnyitása",
      INITIATE_MARKET_CONTACT: "Kapcsolatfelvétel a piaccal",
      MAKE_BINDING_OFFER: "Kötött ajánlat tétel",
      COMPLETE_NOTARIZATION: "Közjegyzői hitelesítés befejezése",
      RECEIVE_EXTERNAL_COMMISSION: "Külső jutalék átvétele",
      PAY_TIP_PROVIDER_COMMISSION: "Tip szolgáltató jutalékának kifizetése",
      ANALYZE_PROPERTY_DATA: "Ingatlan adatok elemzése",
      CONDUCT_MARKET_ANALYSIS: "Piaci elemzés elvégzése",
      SELECT_EVALUATION_METHOD: "Értékelési módszer kiválasztása",
      INTERNAL_VALIDATION: "Belső ellenőrzés",
      APPROVE_COMMUNICATION: "Kommunikáció jóváhagyása",
      PREPARE_NEGOTIATION_STRATEGY: "Tárgyalási stratégia előkészítése",
      START_NEGOTIATIONS: "Tárgyalások megkezdése",
      CONDUCT_NEGOTIATIONS: "Tárgyalások lefolytatása",
      ADJUST_CONTRACT_TERMS: "Szerződési feltételek módosítása",
      FINALIZE_AND_SIGN_CONTRACT: "Szerződés véglegesítése és aláírása"
    },
    gkProcessState: {
      OPEN: "Nyitott",
      IN_PROGRESS: "Folyamatban",
      DONE: "Kész"
    },
    objectType: {
      TYPE_BAUERNHOF: "Gazdaság",
      TYPE_EINFAMILIENHAUS: "Családi ház",
      TYPE_MEHRFAMILIENHAUS: "Töbcsaládos ház",
      TYPE_WOHNUNG: "Lakás",
      TYPE_GEWERBEFLAECHE: "Kereskedelmi terület",
      TYPE_LAGERHALLE: "Raktár",
      TYPE_GRUNDSTUECK_WOHNEN: "Lakótelek",
      TYPE_GEWERBE: "Üzleti",
      TYPE_MIXED_USE: "Vegyes használat",
      TYPE_RESIDENTIAL_MFH: "Lakó töbcsaládos ház",
      TYPE_RESIDENTIAL_EFH: "Lakó családi ház",
      TYPE_RESIDENTIAL_DHH: "Lakó kétcsaládos ház",
      TYPE_RESIDENTIAL_ETW: "Lakó társasház",
      TYPE_RESIDENTIAL_WOHN_GESCHAEFTSHAUS: "Lakó-üzleti ház",
      TYPE_RETAIL: "Kiskereskedelem",
      TYPE_HOSPITALITY: "Vendéglátás",
      TYPE_HEALTHCARE: "Egészségügy",
      TYPE_OFFICE: "Iroda",
      TYPE_LOGISTIK: "Logisztika",
      TYPE_LIGHT_INDUSTRIAL: "Könnyű ipari",
      TYPE_INFRASTRUCTURE: "Infrastruktúra",
      TYPE_ENERGY_INFRASTRUCTURE: "Energia infrastruktúra"
    }
  },
  tasks: "Feladat | Feladatok",
  notification: {
    title: "Értesítések",
    deleteAll: "Összes törlése",
    noData: "Nincs értesítés"
  },
  wallet: {
    token: "Token | Tokenek",
    coin: "Érme | Érmék"
  },
  education: {
    to: "Oktatáshoz"
  },
  files: {
    download: "Fájl letöltése",
    upload: "Új fájl feltöltése"
  },
  process: {
    coinFormula:
      "Ez az érték képviseli az ingatlan tulajdonosával kötött közvetítői szerződés létrehozása miatt járó kompenzációdat. A kapott jutalék alapján kerül kiszámításra, amely az eladási ártól függ. Az eladó jutalékából (belső jutalék) Te részesedésed",
    ownerConfirmation: {
      title: "Tulajdonos hozzájárulásának nyilatkozata",
      text: "A tulajdonos hozzájárulása a folyamat legfontosabb része.<br/>Az EstateBoost a <b>átláthatóság</b> és <b>őszinteség</b> mellett áll, és kifejezetten csak azokat az adatokat gyűjti, amelyeket a tulajdonos <b>önkéntesen</b> és <b>szándékosan biztosít</b>.",
      alert: "Kifejezetten hangsúlyozzuk, hogy cégként nincs érdeklődésünk olyan adatok iránt, amelyeket jogilag nem oszthatnak meg velünk.*",
      footNote: "*Bármilyen visszaélés esetén a fiók felfüggesztésre kerül.",
      confirm: "Ezennel megerősítem, hogy a tulajdonos hozzájárulását megkaptam az adatai platformunkon történő közzétételéhez.",
      download: "Letöltheted a PDF sablont itt",
      upload: "Új hozzájárulási nyilatkozat feltöltése",
      noConfirmation: "Nem lett feltöltve hozzájárulási nyilatkozat",
      noConfirmation2: "Nincs hozzájárulás."
    },
    objectData: {
      title: "Add meg az objektum adatait"
    },
    verification: {
      title: "Ellenőrzés",
      text: "Az objektumod most ellenőrzésre kész.<br/>Kérjük, nézd át még egyszer a bejegyzéseidet.",
      warning: "Miután elküldöd az ellenőrzést, többé nem módosíthatod az objektum adatait.",
      button: "Ellenőrizd most",
      running:
        '<p>Az ellenőrzés folyamatban van. Értesítünk, amint elértük a tulajdonost és ellenőriztük a bejegyzéseket.</p><p>Aktuális állapot:<br/> <small class="text-accent" v-html="state.message"></small></p>'
    },
    contract: {
      title: "Közvetítői szerződés létrejött",
      text: 'A tulajdonossal kötött közvetítői szerződés <span class="success">aktív</span>.<br/>Mostantól <v-chip >{date}</v-chip>-ig hirdetheted "saját" ingatlanodat.'
    },
    marketing: {
      title: "Marketing"
    },
    notaryAppointment: {
      title: "Közjegyzői időpont"
    },
    provision: {
      title: "Jutalék átvétele"
    },
    collect: {
      title: "Érmék beszedése",
      text: "Mostantól beszedheted az érméid! Ezenkívül az érméket euróra is átválthatod, majd a regisztrált számládra utalhatod.",
      alreadyCollected: "Már beszedted az érméidet."
    },
    overallProgress: "Összesített előrehaladás",
    collectCoins: "Érmék beszedése most",
    success: "Siker",
    reward: "Érme jutalom"
  },
  topBar: {
    saveToHomescreen: "Mentsd el a WebApp-ot a kezdőképernyőre!",
    apple: "iPhone/iPad: Koppints a megosztás gombra, majd válaszd az 'Add to Home Screen' opciót.",
    android: "Koppints a három pontra, majd válaszd az 'Add to Home Screen' opciót."
  },
  greeting: {
    morning: "Jó reggelt",
    lunch: "Ebédidő",
    evening: "Jó estét",
    default: "Szép napot"
  },
  buttons: {
    buy: "Vásárlás",
    close: "Bezárás",
    continue: "Folytatás",
    save: "Mentés",
    send: "Küldés",
    cancel: "Mégse",
    open: "Megnyitás"
  },
  bankDetails: {
    IBAN: "IBAN",
    BIC: "BIC",
    bankName: "Bank neve",
    accountHolder: "Számlatulajdonos"
  },
  user: {
    level: "Szint",
    profile: {
      tipster: "Tipster",
      checkEducation: "*Az ingatlan hozzáadásához el kell végezned az adatvédelmi oktatásunkat."
    },
    registeredAt: "Tagként csatlakozott",
    firstName: "Keresztnév",
    lastName: "Vezetéknév",
    email: "Email",
    phone: "Telefonszám",
    mobile: "Mobil szám",
    bio: "Életrajz",
    birthDate: "Születési dátum",
    messages: {
      emailVerified: "Az emailed megerősítve.",
      emailNotVerified: "Az alkalmazás teljes körű használatához igazolnod kell az emailed. <br/>Kattints a <b>Erősítsd meg az emailt most</b> gombra, és küldünk egy emailt.",
      validateBtn: "Erősítsd meg az emailt most",
      changeBirthDate: "Születési dátum frissítése"
    }
  },
  address: {
    edit: "Cím szerkesztése",
    title: "Címek",
    showOnMap: "Megjelenítés a térképen",
    save: "Új cím hozzáadása",
    street: "Utca",
    houseNumber: "Házszám",
    zip: "Irányítószám",
    city: "Város",
    bundesland: "Állam",
    country: "Ország"
  },
  yourObjects: "Ingatlanaid",
  allObjects: "Minden ingatlan",
  form: {
    search: "Keresés",
    invalidDate: "Érvénytelen dátum"
  },
  noData: {
    default: "Nem található adat",
    reactive: "Nem található {obj}",
    small: "Nincs {obj}"
  },
  object: {
    owner: {
      title: "Ingatlan tulajdonos",
      fullName: {
        title: "Tulajdonos teljes neve",
        hint: "Kérjük, vedd figyelembe, hogy a tulajdonos hozzájárulása nélküli adatszolgáltatás nem megengedett."
      },
      email: "Tulajdonos email címe",
      telephone: "Tulajdonos telefonszáma",
      error: {
        importantData: "Fontos adat hiányzik!",
        nameMissing: "A tulajdonos neve hiányzik.",
        emailMissing: "A tulajdonos email címe hiányzik.",
        phoneMissing: "A tulajdonos telefonszáma hiányzik."
      }
    },
    sale: "ELADÁS",
    retirement: "Nyugdíj",
    slots: {
      locked: "Zárolt slot",
      unlock: "Slot feloldása",
      buy: {
        confirm: "Az OK gombra kattintva {token} token levonásra kerül, és 1 slot hozzáadásra kerül.",
        title: "Új slot vásárlása az ingatlanok bővítéséhez.",
        insufficientSales: "Legalább 2 ingatlan eladása szükséges az új slot feloldásához.",
        commercialActivity: "Az év harmadik ingatlanától kereskedelmi tevékenységnek minősül. Kérjük, vedd figyelembe, hogy lehet, hogy üzleti regisztrációra lesz szükséged.",
        priceCalculation: "Az ár a nemrég befejezett tippek alapján kerül kiszámításra."
      }
    },
    saled: "eladva",
    object: "Objektum | Objektumok",
    estate: "Ingatlan | Ingatlanok",
    price: "Ár",
    istFactor: "Hozam tényező / Valós hozam",
    usableArea: "Hasznos terület",
    flatArea: "Lakóterület",
    baseArea: "Telek mérete",
    tooltip: "Becsült közvetítői jutalék",
    yearOfConstruction: "Építési év",
    onlineSince: "Online óta",
    contacts: "Kapcsolattartó",
    selectContact: "Kapcsolattartó kiválasztása",
    pictures: "Képek",
    addNote: "Megjegyzés hozzáadása...",
    noNote: "Nincs megjegyzés",
    add: "Ingatlan hozzáadása",
    expected: "becsült",
    actual: "valós",
    provision: "Jutalék",
    expectedProvision: "Becsült közvetítői jutalék",
    fairValue: "Piaci érték",
    fairValueExpected: "Becsült piaci érték",
    fairValueExpectedExplanation: "A becsült piaci érték különböző tényezők alapján kerül kiszámításra, és eltérhet az eladási ártól."
  },
  settingsPopup: {
    admin: {
      name: "Admin",
      title: "Adminisztrációs felület",
      objects: {
        title: "Ingatlanok",
        subtitle: "Irányítsd az ingatlanjaidat",
        verification: {
          title: "Ellenőrzés",
          whyError: "Miért nem sikerült az ellenőrzés?",
          whyErrorPlaceholder: "Indok itt...",
          whyErrorLabel: "Hiba oka",
          send: "Küldés",
          cancel: "Mégse",
          declarationOfConsent: "Tulajdonos hozzájárulási nyilatkozat",
          sellType: "Érdeklődés",
          objectType: "Ingatlan típusa",
          livingArea: "Lakóterület",
          baseArea: "Telek mérete",
          owner: "Tulajdonos",
          createdBy: "létrehozta: "
        },
        marketing: {
          title: "Marketing",
          edit: "Szerkesztés",
          view: "Megtekintés"
        },
        success: "Siker",
        error: "Hiba",
        edit: {
          brokerCommissionGk: {
            title: "Közvetítői jutalék GK",
            fairValue: "Szerződés előtti piaci érték",
            salesValue: "Eladási ár",
            provision: "Jutalék %",
            tipgiverProvision: "Tipadó jutalék %",
            brokerCommission: "Közvetítői jutalék %",
            closeBtn: "Bezárás"
          },
          userView: "Felhasználói nézet",
          overview: {
            title: "Áttekintés"
          },
          agentContract: {
            title: "Közvetítői szerződés",
            dateEnd: "Szerződés lejárati dátuma",
            signedCheckbox: "Szerződés aláírva",
            loadingBtn: "Szerződés objektum létrehozása",
            saveBtn: "Mentés"
          },
          marketing: {
            title: "Marketing",
            date: "Közjegyzői időpont: ",
            successCheckbox: "Ingatlan hirdetve",
            saveBtn: "Mentés",
            loadingBtn: "Közjegyzői időpont objektum létrehozása"
          },
          payment: {
            title: "Fizetés",
            saveBtn: "Ingatlan lezárása felhasználó számára",
            infoText1: "*A felhasználó mostantól beszedheti az érméit",
            infoText2: "A felhasználó mostantól kiveheti az érméit"
          },
          fairValue: "Piaci érték",
          provision: "Jutalék",
          tipGiver: "Tipadó"
        }
      },
      trainingCenter: {
        title: "Képzési Központ",
        subtitle: "Képzések szerkesztése és hozzáadása",
        courseSection: {
          noData: "Nem található szekció",
          addBtn: "Hozzáadás",
          addTitle: "Cím",
          addDescription: "Leírás",
          addChapter: "Fejezet",
          addSaveBtn: "Új kurzus feltöltése"
        }
      },
      payout: {
        title: "Kifizetések",
        subtitle: "Minden kifizetés"
      },
      statistics: {
        title: "Statisztikák",
        subtitle: "Statisztikák"
      }
    },
    gkAdmin: {
      name: "Kereskedelmi Ügyfelek Admin nézet",
      details: {}
    },
    accountDetails: {
      name: "Fiók adatai",
      details: {}
    },
    address: {
      name: "Címek",
      details: {}
    },
    bankDetails: {
      name: "Bank adatok",
      details: {}
    },
    shareProfile: {
      name: "Profil megosztása",
      details: {}
    },
    settings: {
      name: "Beállítások",
      details: {}
    },
    changePassword: {
      name: "Jelszó változtatása",
      details: {
        newPassword: "Új jelszó",
        confirmPassword: "Jelszó megerősítése",
        oldPassword: "Jelenlegi jelszó",
        change: "Jelszó változtatása",
        validation: {
          title: "A jelszónak az alábbi követelményeknek kell megfelelnie:",
          length: "Legalább 8 karakter hosszú",
          number: "Legalább egy számot tartalmaz",
          specialChar: "Legalább egy speciális karaktert tartalmaz",
          char: "Legalább egy kis- vagy nagybetűt tartalmaz",
          match: "A jelszavak nem egyeznek",
          required: "Kötelező mező"
        }
      }
    },
    achievements: {
      name: "Eredmények",
      details: {}
    },
    trainingCenter: {
      name: "Képzési Központ",
      details: {}
    },
    docTemplates: {
      name: "Dokumentum sablonok",
      details: {}
    },
    logout: "Kijelentkezés"
  },
  filter: {
    headline: "Szűrő",
    deleteAll: "Összes szűrő törlése",
    sort: {
      by: "Rendezés szerint",
      price: {
        desc: "Legmagasabb ár",
        asc: "Legalacsonyabb ár"
      },
      progress: {
        desc: "Legmagasabb előrehaladás",
        asc: "Legalacsonyabb előrehaladás"
      },
      area: {
        desc: "Legnagyobb telek mérete",
        asc: "Legkisebb telek mérete"
      },
      usableArea: {
        desc: "Legnagyobb hasznos terület",
        asc: "Legkisebb hasznos terület"
      },
      yearOfConstruction: {
        desc: "Legújabb építési év",
        asc: "Legrégebbi építési év"
      },
      returnOfInvestment: {
        desc: "Legmagasabb ROI",
        asc: "Legalacsonyabb ROI"
      },
      onlineSince: {
        desc: "Legújabb hirdetés",
        asc: "Legrégebbi hirdetés"
      }
    },
    min: "Min",
    max: "Max",
    found: {
      init: "még nincs objektum",
      object: "Objektum találat | Objektum találatok"
    },
    geschaeftskunden: "Kereskedelmi ügyfelek"
  },
  common: {
    uploadImages: "Képek feltöltése",
    save: "Mentés",
    cancel: "Mégse",
    close: "Bezárás",
    images: "Képek",
    messages: {
      percentError: "Kérjük, adj meg egy 0 és 100 közötti számot",
      imageUploadSuccess: "Képek sikeresen feltöltve",
      imageUploadError: "Hiba történt a képek feltöltésekor",
      imageDeleteSuccess: "Kép sikeresen törölve",
      imageDeleteError: "Hiba történt a kép törlésekor",
      updateSuccess: "Sikeresen frissítve",
      updateError: "Hiba történt a frissítés során"
    }
  },
  tour: {
    startTooltip: "Indítsd el az útmutatót",
    next: "Következő",
    prev: "Előző",
    done: "Kész",
    steps: {
      objectOverview: {
        title: "Objektum áttekintése",
        intro: "Itt láthatod az összes ajánlott üzletet, amit jelenleg a piacra képviselünk számodra. A várt ajánlási díjad zöld színnel jelenik meg, az aktuális állapot pedig narancssárga. Bármelyik elemre kattintva megtekintheted a potenciális üzlet részleteit."
      },
      search: {
        title: "Keresés",
        intro: "Kulcsszavak alapján kereshetsz, hogy szűkítsd az objektumokat vagy megtaláld a specifikus egyiket."
      },
      filter: {
        title: "Szűrő",
        intro: "Ezen kívül szűrheted is az eredményeket..."
      },
      sort: {
        title: "Rendezés",
        intro: "és rendezheted is azokat a preferált sorrendben."
      },
      feedback: {
        title: "Visszajelzés",
        intro: "Őszinte visszajelzésed fontos számunkra. Kérjük, oszd meg velünk, hogyan tudnánk javítani az EstateBoost-ot."
      }
    }
  }
};
