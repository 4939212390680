<template>
  <v-bottom-navigation
    color="accent"
    style="overflow: visible"
    order="1"
    class="d-flex text-text bg-primary flex-wrap"
  >
    <v-btn @click="$router.push('/')">
      <v-icon>mdi-monitor-dashboard</v-icon>
    </v-btn>
    <v-btn @click="$router.push('/wallet')">
      <v-icon>mdi-wallet-bifold-outline</v-icon>
    </v-btn>
    <div style="position: relative; width: 100px">
      <div
        class="border-gradient-wrapper"
        style="
          width: 65px;
          height: 65px;
          position: absolute;
          border-radius: 100%;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%) translateY(-10%);
          z-index: 1;
        "
      >
        <v-sheet
          v-ripple
          color="accent"
          width="100%"
          height="100%"
          style="border-radius: 30px; cursor: pointer"
          class="d-flex justify-center align-center elevation-1 bg-accent"
          @click="$router.push('/immobilie/erstellen')"
        >
          <v-icon size="large">mdi-plus</v-icon>
        </v-sheet>
      </div>
    </div>
    <v-btn @click="$router.push('/weiterbildung')">
      <v-icon>mdi-bookshelf</v-icon>
    </v-btn>
    <v-btn @click="$router.push('/profil')">
      <v-icon>mdi-account-outline</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: "AppNavigation",
};
</script>

<style scoped>
.border-gradient-wrapper {
  background: linear-gradient(0deg, rgba(61, 62, 64, 1) 77%, transparent 77%);
  padding: 5px;
}
</style>
