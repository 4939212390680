import {de} from '@/plugins/l18i/languages/de'
import {gb} from "@/plugins/l18i/languages/gb";
import {it} from "@/plugins/l18i/languages/it";
import {tr} from "@/plugins/l18i/languages/tr";
import {hu} from "@/plugins/l18i/languages/hu";
import {sl} from "@/plugins/l18i/languages/sl";

export const messages = {
      de:de,
      gb:gb,
      it:it,
      tr:tr,
      hu:hu,
      si:sl

}
