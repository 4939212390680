<template>
  <v-btn
      variant="icon"
      icon="mdi-help-circle-outline"
      v-tooltip:start="this.$t('tour.startTooltip')"
      @click="startTour"
  ></v-btn>
</template>
<script>
import {useAppStore} from "@/stores/app.module";

export default {
  name: 'StartTour',
  setup(){
    const appStore = useAppStore();
    return{
      appStore
    }
  },
  methods:{
    async startTour() {
      await this.$router.push("/");
      this.appStore.$patch((state) => {
        state.showTour = true;
      });
    },
  },
  props: {
  }
}
</script>