import { createApp } from "vue";
import App from "./App.vue";
import "@/style/global.scss";
import Trend from "vue3trend";
import VueShepherdPlugin from "vue-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import VueGtag from "vue-gtag";
import "intro.js/introjs.css";
import "@/plugins/jira/issue-collector.js";
import Vue3Tour from "vue3-tour";

import "vue3-tour/dist/vue3-tour.css";

//Store
import { createPinia } from "pinia";
const pinia = createPinia();

// Vuetify
import "material-design-icons-iconfont/dist/material-design-icons.css";
import "vuetify/styles";
import { createVuetify } from "vuetify";
import * as components from "vuetify/components";

import * as directives from "vuetify/directives";
// make sure to also import the coresponding css
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader

//L18n Vuetify
import { createI18n, useI18n } from "vue-i18n";
import { messages } from "@/plugins/l18i/messages";
import { createVueI18nAdapter } from "vuetify/locale/adapters/vue-i18n";
const i18n = createI18n({
  legacy: false,
  locale: "de",
  fallbackLocale: "gb",
  messages: messages,
});

//Router
import router from "./router";

import "animate.css";

import "./registerServiceWorker";
const vuetify = createVuetify({
  options: {
    customProperties: true,
  },
  locale: {
    adapter: createVueI18nAdapter({ i18n, useI18n }),
  },
  defaults: {
    VBtn: {
      class: "rounded-xl",
      style: [{ textTransform: "none" }],
    },
  },
  theme: {
    defaultTheme: "light",
    themes: {
      dark: {
        dark: true,
        colors: {
          text: "#FFFFFF",
          green: "#4CAF50",
          warning: "#FFC107",
          primary: "#282828",
          "primary-lighten-1": "#424242", //#B5CFF8  // #E53935           //#1b1b1b
          secondary: "#3D3E40", // #FFCDD2                   //#313131
          accent: "#CCA876",
          blue: "#3D4C62", //
          "blue-darken-1": "#304159",
          grey: "#CBC9D4",
          darkblue: "#130160",
        },
      },
      light: {
        dark: false,
        colors: {
          text: "#272727",
          //text: "#ad3861", // Test farbe
          green: "#0d480f",
          warning: "#694f00",
          primary: "#F2F2F2", // White
          secondary: "#F5F5F5", // Light Grey
          accent: "#CCA876", // Light Blue
          blue: "#3d5a71", // Blue
          "blue-darken-1": "#304159", // Darker Blue
          grey: "#9E9E9E", // Grey
          darkblue: "#0D47A1", // Dark Blue
        },
      },
    },
  },
  components: {
    ...components,
  },
  directives,
});

import { vuetifyProTipTap } from "./plugins/tiptab";

//HOTJAR
//import VueHotjar from "vue-hotjar-next";
//let isProduction = process.env.VUE_APP_IS_PRODUCTION === "true";
//if (!isProduction) isProduction = false;
createApp(App)
  //.use(VueHotjar, {
  //  id: 5244131,
  //  isProduction: isProduction,
  //  snippetVersion: 6,
  //})
  .use(i18n)
  .use(Vue3Tour)
  .use(vuetifyProTipTap)
  .use(VueShepherdPlugin)
  .use(Trend)
  .use(pinia)
  .use(router)
  .use(vuetify)
  .use(VueGtag, {
    config: { id: "G-LWQ2F0CG9P" },
  })
  .mount("#app");
