import { sl as vsl } from "vuetify/locale";

export const sl = {
  $vuetify: {
    ...vsl,
  },
  theme: {
    dark: "Aktiviraj temni način",
    light: "Aktiviraj svetli način",
  },
  refer: "Priporoči objekt",
  referHint2: "Kmalu na voljo! Pošljite nam svoje nepremičnine.",
  business: "Podjetje",
  welcome: "Dobrodošli pri EstateBoost!",
  private: "Fizična oseba",
  password: "Geslo",
  required: "Prosimo, izpolnite to polje",
  personalInformation: "Osebni podatki",
  readMore: "Preberi več",
  auth: {
    loginWithLinkedIn: "Prijavite se z LinkedIn",
    loginWithSocials: "Prijavite se z družbenimi omrežji",
    orUserNameAndPassword: "ali uporabniško ime in geslo",
    alreadyHaveAccount: "Imate že račun? Prijavite se tukaj.",
    inputPassword: "Vnesite geslo",
    repeatPassword: "Ponovite geslo",
    password: {
      requirements: {
        title: "Geslo mora izpolnjevati naslednje zahteve:",
        oneNumber: "vsebuje vsaj eno številko",
        oneSpecialChar: "vsebuje vsaj en poseben znak",
        oneChar: "vsebuje vsaj eno črko (veliko ali malo)",
        length: "naj bo dolgo vsaj 8 znakov",
      },
    },
    forgotPassword: "Ste pozabili geslo?",
    createAccount: "Ustvari račun",
    register: {
      title: "Registracija",
      privacy: "Prebral sem {agb} in {privacy} ter ju sprejemam.",
      privacyError:
        "Za registracijo morate sprejeti pogoje in pravila zasebnosti.",
      business: {
        checkbox: "Sem zavezanec za DDV",
        name: "Ime podjetja",
        legalForm: "Pravna oblika",
        uStIdNr: "ID za DDV",
        hint: "Kdo je zavezan za DDV?",
        hintText:
          "Na splošno so vsi podjetniki v Nemčiji zavezani za DDV, če njihov letni promet preseže 22.000 €. Če je promet podjetja pod to mejo, se šteje za malo podjetje.\n" +
          "Mala podjetja, pa tudi samostojni strokovnjaki, lahko izkoristijo t.i. režim malih podjetij, ki omogoča oprostitev DDV. Ta režim služi kot poenostavitev, ki podjetnikom olajša računovodstvo in pripravljalne davčne naloge. Kdor ne zaračunava DDV-ja in ga ne plača davčnemu uradu, pa ne more uveljavljati vstopnega DDV-ja.\n" +
          "Pravna podlaga za oprostitev DDV je določena v § 19 Abs. 1 UStG, ki določa, da so podjetniki, katerih pretekli letni promet je bil manjši od 22.000 € in se pričakuje, da bo tekoče leto manj kot 50.000 €, oproščeni DDV.\n" +
          "Če mala podjetja in samostojni strokovnjaki presežejo to mejo, se to v naslednjem letu pokaže: preidejo na običajen režim in morajo DDV zaračunavati na svoje izdelke in storitve ter ga navesti na računih.\n",
      },
      businessOrPrivate: "Registrirajte se kot podjetje ali kot fizična oseba?",
      businessOrPrivateHint:
        "EstateBoost ponuja prilagojene funkcije za podjetja in posameznike, da zadosti vašim specifičnim potrebam.",
    },
  },
  points: "Točka | Točke",
  rank: "Rang",
  calculatedReferralFee: "ocenjena posredniška provizija",
  profile: {
    share: {
      text:
        "To je tvoja osebna povezava do profila. Je zaščitena z naključno generirano kodo, zato lahko tvoj profil ogledajo le osebe, ki imajo to povezavo.\n\nZ generiranjem nove povezave stara izgubi veljavnost.",
      generate: "Generiraj novo",
      share: "Deli profil",
      sharetext: "Obišči moj profil na EstateBoost.",
    },
  },
  achievements: {
    empty: "Ni dodatnih dosežkov",
  },
  rewards: {
    unclaimed: "Ni prevzeto",
    claimed: "Prevzeto",
  },
  dashboard: {
    your_properties: "Tvoje nepremičnine",
    ranking: "Uvrstitev",
    education: "Dodatno izobraževanje",
    to_wallet: "Do denarnice",
    compareValue: "Primerjalna vrednost: vedno 1. dan v mesecu",
  },
  dailyreward: {
    button: "Dnevni bonus",
    hint: "Lahko ga prejmeš samo enkrat dnevno.",
    claim: "Prevzemi",
    thanks: "Hvala",
    schade: "Škoda",
  },
  time: {
    days: "Dnevi",
    hours: "Ure",
    minutes: "Minute",
    seconds: "Sekunde",
  },
  confirm_email: {
    text: "Tvoj email še ni potrjen.",
    button: "Potrdi zdaj",
  },
  verification: {
    failureReason: "Vzrok neuspeha",
  },
  enum: {
    EVerificationState: {
      ANGERUFEN: "že poklican",
      NICHT_DURCHGEKOMMEN: "ni dosežen",
      WARTE_AUF_RUECKMELDUNG: "čakam na povratno informacijo",
      VERIFIZIERUNG_FEHLGESCHLAGEN: "preverjanje ni uspelo",
      VERIFIZIERUNG_LÄUFT: "preverjanje poteka",
    },
    bToCState: {
      PENDING: "Na čakanju",
      SUCCESS: "Uspešno",
      COINS_COLLECTED: "Zbrani kovanci",
      ARCHIVED: "Arhivirano",
      CANCELED: "Preklicano",
    },
    pending: "Na čakanju",
    gkProcess: {
      SEND_TEASER_EXPOSE: "Pošlji teaser/razkritje",
      AWAIT_APPROVAL: "Čakaj na odobritev",
      ADD_PROPERTY_IN_ESTATEBOOST: "Dodaj nepremičnino v EstateBoost",
      VERIFY_INPUT: "Preveri vnose",
      CREATE_EXPOSE_DATA_ROOM: "Ustvari razkritje/podatkovno sobo",
      EVALUATE_PROPERTY: "Ocenitev nepremičnine",
      APPROVE_EVALUATION: "Odobri oceno",
      OPEN_DUE_DILIGENCE_PROCESS: "Začni postopek pregleda zaradi skrbnosti",
      INITIATE_MARKET_CONTACT: "Vzpostavi stik s trgom preko ujemanja",
      MAKE_BINDING_OFFER: "Podaj zavezujočo ponudbo",
      COMPLETE_NOTARIZATION: "Zaključi notarizacijo",
      RECEIVE_EXTERNAL_COMMISSION: "Prejmi zunanjo provizijo",
      PAY_TIP_PROVIDER_COMMISSION: "Izplačaj provizijo posrednika",
      ANALYZE_PROPERTY_DATA: "Zberi podatke o nepremičnini",
      CONDUCT_MARKET_ANALYSIS: "Izvedi tržno analizo",
      SELECT_EVALUATION_METHOD: "Izberi metodo ocenjevanja",
      INTERNAL_VALIDATION: "Notranja validacija",
      APPROVE_COMMUNICATION: "Odobri komunikacijo",
      PREPARE_NEGOTIATION_STRATEGY: "Pripravi strategijo pogajanj",
      START_NEGOTIATIONS: "Začni pogajanja",
      CONDUCT_NEGOTIATIONS: "Izvedi pogajanja",
      ADJUST_CONTRACT_TERMS: "Prilagodi pogoje pogodbe",
      FINALIZE_AND_SIGN_CONTRACT: "Zaključi in podpiši pogodbo",
    },
    gkProcessState: {
      OPEN: "Odprto",
      IN_PROGRESS: "V teku",
      DONE: "Zaključeno",
    },
    objectType: {
      TYPE_BAUERNHOF: "Kmetija",
      TYPE_EINFAMILIENHAUS: "Enodružinska hiša",
      TYPE_MEHRFAMILIENHAUS: "Večstanovanjska hiša",
      TYPE_WOHNUNG: "Stanovanje",
      TYPE_GEWERBEFLAECHE: "Poslovni prostor",
      TYPE_LAGERHALLE: "Skladiščna hala",
      TYPE_GRUNDSTUECK_WOHNEN: "Stanovanjska parcela",
      TYPE_GEWERBE: "Poslovni objekt",
      TYPE_MIXED_USE: "Mešana raba",
      TYPE_RESIDENTIAL_MFH: "Večstanovanjska hiša",
      TYPE_RESIDENTIAL_EFH: "Enodružinska hiša",
      TYPE_RESIDENTIAL_DHH: "Polovična hiša",
      TYPE_RESIDENTIAL_ETW: "Stanovanje v lasti",
      TYPE_RESIDENTIAL_WOHN_GESCHAEFTSHAUS: "Stanovanjsko-poslovna stavba",
      TYPE_RETAIL: "Trgovina na drobno",
      TYPE_HOSPITALITY: "Gostinstvo in hotelirstvo",
      TYPE_HEALTHCARE: "Zdravstvo",
      TYPE_OFFICE: "Pisarna",
      TYPE_LOGISTIK: "Logistika",
      TYPE_LIGHT_INDUSTRIAL: "Lahka industrija",
      TYPE_INFRASTRUCTURE: "Infrastruktura",
      TYPE_ENERGY_INFRASTRUCTURE: "Energijska infrastruktura",
    },
  },
  tasks: "Naloga | Naloge",
  notification: {
    title: "Obvestila",
    deleteAll: "Izbriši vsa",
    noData: "Ni obvestil",
  },
  wallet: {
    token: "Žeton | Žetoni",
    coin: "Kovanec | Kovanci",
  },
  education: {
    to: "Za izobraževanje",
  },
  files: {
    download: "Prenesi datoteko",
    upload: "Naloži novo datoteko",
  },
  process: {
    coinFormula:
      "Ta vrednost predstavlja tvojo nagrado za sklenitev posredniške pogodbe z lastnikom nepremičnine preko tvojega tipa. Izračuna se na podlagi posredniške provizije, ki je odvisna od prodajne cene. Tvoj delež v proviziji prodajalca (notranja provizija) znaša",
    ownerConfirmation: {
      title: "Soglasje lastnika",
      text:
        "Soglasje je najpomembnejši element postopka.<br/>EstateBoost zagovarja <b>transparentnost</b> in <b>poštenost</b> ter želi izrecno zbirati le tiste podatke, ki jih lastnik <b>prostovoljno</b> in z <b>polno namero</b> posreduje.",
      alert:
        "Izrecno poudarjamo, da podjetje nima interesa za podatke, ki jih pravno ne smejo posredovati nam.*",
      footNote: "*Vsaka zloraba vodi do blokade računa.",
      confirm:
        "S tem potrjujem, da imam soglasje lastnika nepremičnine za vnos njegovih osebnih podatkov na naši platformi.",
      download: "Prenesi PDF predlogo tukaj",
      upload: "Naloži novo soglasje",
      noConfirmation: "Nobenega soglasja ni naloženega",
      noConfirmation2: "Soglasja ni bilo podanega.",
    },
    objectData: {
      title: "Vnesi podatke o objektu",
    },
    verification: {
      title: "Preverjanje",
      text:
        "Tvoj objekt je sedaj pripravljen za preverjanje.<br/>Prosimo, še enkrat preveri svoje vnose.",
      warning:
        "Ko pošlješ preverjanje, podatkov o objektu ne boš mogel več urejati.",
      button: "Preveri zdaj",
      running:
        '<p>Preverjanje poteka. Obvestili te bomo, ko bomo dosegli lastnika in ko bodo tvoji vnosi potrjeni.</p><p>Trenutni status:<br/> <small class="text-accent" v-html="state.message"></small></p>',
    },
    contract: {
      title: "Posredniška pogodba sklenjena",
      text:
        'Posredniška pogodba z lastnikom je <span class="success">aktivna</span>.<br/>Imamo čas do <v-chip >{date}</v-chip>, da "tvoj" objekt tržimo.',
    },
    marketing: {
      title: "Trženje",
    },
    notaryAppointment: {
      title: "Notarski termin",
    },
    provision: {
      title: "Prejmi posredniško provizijo",
    },
    collect: {
      title: "Zberi kovance",
      text:
        "Zdaj lahko zbiraš svoje kovance! Prav tako lahko kovance zamenjaš za evre na tvojem navedenem računu.",
      alreadyCollected: "Svoje kovance si že zbral.",
    },
    overallProgress: "Skupni napredek",
    collectCoins: "Zberi kovance zdaj",
    success: "Uspeh",
    reward: "Dobiček kovancev",
  },
  topBar: {
    saveToHomescreen: "Shrani spletno aplikacijo na domači zaslon!",
    apple:
      "iPhone/iPad: Tapnite gumb za deljenje in izberite „Dodaj na domači zaslon“.",
    android:
      "Tapnite tri pike in izberite „Dodaj na začetni zaslon“.",
  },
  greeting: {
    morning: "Dobro jutro",
    lunch: "Dober tek",
    evening: "Dober večer",
    default: "Dober dan",
  },
  buttons: {
    buy: "Kupi",
    close: "Zapri",
    save: "Shrani",
    send: "Pošlji",
    continue: "Nadaljuj",
    cancel: "Prekliči",
    open: "Odpri",
  },
  bankDetails: {
    IBAN: "IBAN",
    BIC: "BIC",
    bankName: "Ime banke",
    accountHolder: "Lastnik računa",
  },
  user: {
    level: "Raven",
    profile: {
      tipster: "Priporočilec",
      checkEducation:
        "*za dodajanje nepremičnine je potrebno opraviti naše usposabljanje o varstvu podatkov.",
    },
    registeredAt: "Član od",
    firstName: "Ime",
    lastName: "Priimek",
    email: "Email",
    phone: "Telefonska številka",
    mobile: "Mobilna številka",
    bio: "Biografija",
    birthDate: "Datum rojstva",
    messages: {
      emailVerified: "Tvoj email je potrjen.",
      emailNotVerified:
        "Za popolno uporabo aplikacije moraš potrditi svoj email. <br/>Klikni <b>Potrdi email zdaj</b> in poslali ti bomo email na tvoj naslov.",
      validateBtn: "Potrdi email zdaj.",
      changeBirthDate: "Posodobi datum rojstva",
    },
  },
  address: {
    titleSingle: "Naslov",
    edit: "Uredi naslov",
    title: "Naslovi",
    showOnMap: "Prikaži na zemljevidu",
    save: "Dodaj nov naslov",
    street: "Ulica",
    streetAndNumber: "Ulica in hišna številka",
    houseNumber: "Hišna številka",
    zip: "Poštna številka",
    city: "Mesto",
    bundesland: "Pokrajina",
    country: "Država",
  },
  yourObjects: "Vaši objekti",
  allObjects: "Vsi objekti",
  form: {
    search: "Išči",
    invalidDate: "Neveljaven datum",
  },
  noData: {
    default: "Ni podatkov",
    reactive: "Ni najdenih {obj}",
    small: "Brez {obj}",
  },
  object: {
    owner: {
      title: "Lastnik objekta",
      fullName: {
        title: "Polno ime lastnika",
        hint: "Prosimo, upoštevaj, da ni dovoljeno posredovati teh podatkov brez soglasja lastnika.",
      },
      email: "Email lastnika",
      telephone: "Telefonska številka lastnika",
      error: {
        importantData: "Manjkajo pomembni podatki!",
        nameMissing: "Manjka ime lastnika.",
        emailMissing: "Manjka email lastnika.",
        phoneMissing: "Manjka telefonska številka lastnika.",
      },
    },
    sale: "PRODAJA",
    retirement: "NAJEM",
    slots: {
      locked: "Zaklenjen slot",
      unlock: "Odkleni slot",
      buy: {
        confirm:
          "S klikom na OK se ti odšteje {token} žetonov in se doda 1 slot",
        title: "Kupi nov slot, da dodaš več nepremičnin.",
        insufficientSales:
          "Za odklepanje novih slotov moraš prodati vsaj 2 objekte.",
        commercialActivity:
          "Od tretje nepremičnine v letu se šteje za komercialno dejavnost. Prosimo, upoštevaj, da bo morda potrebno registrirati podjetje.",
        priceCalculation:
          "Cena se izračuna na podlagi tvojih zadnjih zaključenih tipov.",
      },
    },
    saled: "prodano",
    object: "Objekt | Objekti",
    estate: "Nepremičnina | Nepremičnine",
    price: "Cena",
    istFactor: "Dejanski faktor / Dejanska donosnost",
    usableArea: "Uporabna površina",
    flatArea: "Bivalna površina",
    baseArea: "Osnovna površina",
    tooltip: "ocenjena posredniška provizija",
    yearOfConstruction: "Leto gradnje",
    onlineSince: "Na spletu od",
    contacts: "Kontaktne osebe",
    selectContact: "Izberi kontakt",
    pictures: "Slike",
    addNote: "Dodaj opombo...",
    noNote: "Brez opombe",
    add: "Dodaj nepremičnino",
    expected: "ocenjeno",
    actual: "dejansko",
    provision: "Provizija",
    expectedProvision: "Pričakovana posredniška provizija",
    fairValue: "Tržna vrednost",
    fairValueExpected: "ocenjena tržna vrednost",
    fairValueExpectedExplanation:
      "Ocenjena posredniška provizija se izračuna na podlagi različnih dejavnikov in se lahko razlikuje od dejanske vrednosti.",
  },
  settingsPopup: {
    admin: {
      name: "Admin",
      title: "Admin del",
      objects: {
        title: "Objekti",
        subtitle: "Urejajte svoje objekte",
        verification: {
          title: "Preverjanje",
          whyError: "Zakaj je preverjanje spodletelo?",
          whyErrorPlaceholder: "Vzrok tukaj...",
          whyErrorLabel: "Vzrok neuspeha",
          send: "Pošlji",
          cancel: "Prekliči",
          declarationOfConsent: "Soglasje lastnika",
          sellType: "Interes za",
          objectType: "Vrsta objekta",
          livingArea: "Bivalna površina",
          baseArea: "Osnovna površina",
          owner: "Lastnik",
          createdBy: "ustvaril: ",
        },
        marketing: {
          title: "Trženje",
          edit: "Uredi",
          view: "Ogled",
        },
        success: "Uspeh",
        error: "Napaka",
        edit: {
          brokerCommissionGk: {
            title: "Posredniška provizija GK",
            fairValue: "Tržna vrednost pred pogodbo",
            salesValue: "Prodajna vrednost",
            provision: "Provizija v %",
            tipgiverProvision: "Provizija nasvetnika v %",
            brokerCommission: "Posredniška provizija v %",
            brokerCommissionHint:
              "<p>Vrednost posredniške provizije, ki se prikaže stranki, je odvisna od <i>prodajne cene</i>, <i>provizije</i> in <i>posredniške provizije</i>.</p><p>Formula: <b>Tržna vrednost * (Provizija/100) * (Posredniška provizija / 100) = <strong>{calculated}€</strong></b></p>" +
              "<p>Tržna vrednost je določena kot <i>vrednost pred prodajo</i>; ko se prodajna vrednost določi, se provizija izračuna na podlagi le-te.</p>",
            closeBtn: "Zapri",
          },
          userView: "Uporabniški pogled",
          overview: {
            title: "Pregled",
          },
          agentContract: {
            title: "Posredniška pogodba",
            dateEnd: "Konec pogodbe",
            signedCheckbox: "Pogodba je podpisana",
            loadingBtn: "Ustvarjam pogodbeni objekt",
            saveBtn: "Shrani",
          },
          marketing: {
            title: "Trženje",
            date: "Notarski termin: ",
            successCheckbox: "Nepremičnina tržena",
            saveBtn: "Shrani",
            loadingBtn: "Ustvarjam objekt za notarski termin",
          },
          payment: {
            title: "Plačilo",
            saveBtn: "Zaključi nepremičnino za uporabnika",
            infoText1: "*Uporabnik lahko s tem zbere svoje kovance",
            infoText2: "Uporabnik si lahko zdaj prevzame svoje kovance",
          },
          fairValue: "Tržna vrednost",
          provision: "Provizija",
          tipGiver: "Nasvetnik",
        },
      },
      trainingCenter: {
        title: "Center za izobraževanje",
        subtitle: "Urejanje in dodajanje tečajev",
        courseSection: {
          noData: "Ni najdenih sekcij",
          addBtn: "Dodaj",
          addTitle: "Naslov",
          addDescription: "Opis",
          addChapter: "Poglavje",
          addSaveBtn: "Naloži nov tečaj",
        },
      },
      payout: {
        title: "Izplačila",
        subtitle: "Vsa izplačila",
      },
      statistics: {
        title: "Statistika",
        subtitle: "Statistika",
      },
    },
    gkAdmin: {
      name: "Admin pogled za poslovne stranke",
      details: {},
    },
    accountDetails: {
      name: "Podatki računa",
      details: {},
    },
    address: {
      name: "Naslovi",
      details: {},
    },
    bankDetails: {
      name: "Bančni podatki",
      details: {},
    },
    shareProfile: {
      name: "Deli profil",
      details: {},
    },
    settings: {
      name: "Nastavitve",
      details: {},
    },
    changePassword: {
      name: "Spremeni geslo",
      details: {
        newPassword: "Novo geslo",
        confirmPassword: "Potrdi geslo",
        oldPassword: "Trenutno geslo",
        change: "Spremeni geslo",
        validation: {
          title: "Geslo mora izpolnjevati naslednje zahteve:",
          length: "Naj bo dolgo vsaj 8 znakov",
          number: "Vsebuje vsaj eno številko",
          specialChar: "Vsebuje vsaj en poseben znak",
          char: "Vsebuje vsaj eno črko (veliko ali malo)",
          match: "Gesli se ne ujemata",
          required: "Obvezno polje",
        },
      },
    },
    achievements: {
      name: "Dosežki",
      details: {},
    },
    trainingCenter: {
      name: "Center za izobraževanje",
      details: {},
    },
    docTemplates: {
      name: "Predloge dokumentov",
      details: {},
    },
    logout: "Odjava",
  },
  filter: {
    headline: "Filter",
    deleteAll: "Izbriši vse filtre",
    sort: {
      by: "Razvrsti po",
      price: {
        desc: "najvišja cena",
        asc: "najnižja cena",
      },
      progress: {
        desc: "največji napredek",
        asc: "najmanjši napredek",
      },
      area: {
        desc: "največja osnovna površina",
        asc: "najmanjša osnovna površina",
      },
      usableArea: {
        desc: "največja uporabna površina",
        asc: "najmanjša uporabna površina",
      },
      yearOfConstruction: {
        desc: "najnovejša gradnja",
        asc: "najstarejša gradnja",
      },
      returnOfInvestment: {
        desc: "najvišji ROI",
        asc: "najnižji ROI",
      },
      onlineSince: {
        desc: "najnovejša objava",
        asc: "najstarejša objava",
      },
    },
    min: "Min",
    max: "Max",
    found: {
      init: "Še ni objektov",
      object: "Najden objekt | Najdeni objekti",
    },
    geschaeftskunden: "Poslovni kupci",
  },
  common: {
    uploadImages: "Naloži slike",
    save: "Shrani",
    cancel: "Prekliči",
    close: "Zapri",
    images: "Slike",
    messages: {
      percentError:
        "Prosimo, upoštevaj, da je ta vrednost izražena v odstotkih!",
      imageUploadSuccess: "Slike uspešno naložene",
      imageUploadError: "Napaka pri nalaganju slik",
      imageDeleteSuccess: "Slika uspešno izbrisana",
      imageDeleteError: "Napaka pri brisanju slike",
      updateSuccess: "Uspešno posodobljeno",
      updateError: "Napaka pri posodobitvi",
    },
  },
  tour: {
    startTooltip: "Začni ogled",
    next: "Naprej",
    prev: "Nazaj",
    done: "Končano",
    steps: {
      objectOverview: {
        title: "Pregled objekta",
        intro:
          "Tukaj so vsi posredovani objekti, ki jih trenutno zastopamo za vas na trgu. Vašo pričakovano posredniško provizijo vidite v zeleno, trenutni status pa v oranžni. Kliknite na kateri koli objekt, da si ogledate podrobnosti potencialne transakcije.",
      },
      search: {
        title: "Iskanje",
        intro:
          "Iskanje lahko omejite z uporabo ključnih besed ali najdete določen objekt.",
      },
      filter: {
        title: "Filter",
        intro: "Rezultate lahko tudi filtrirate...",
      },
      sort: {
        title: "Razvrščanje",
        intro: "v vrstnem redu, ki vam ustreza.",
      },
      feedback: {
        title: "Povratne informacije",
        intro:
          "Vaše iskrene povratne informacije so za nas pomembne. Prosimo, sporočite nam, kako lahko izboljšamo EstateBoost.",
      },
    },
  },
};
