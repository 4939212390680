<template>
  <v-list-item :disabled="disabled" link :title="title">
    <template #prepend>
      <v-avatar
        :style="{ borderRadius: '5px' }"
        :class="$vuetify.theme.current.dark ? 'bg-black' : 'bg-white'"
      >
        <v-icon
          :color="$vuetify.theme.current.dark ? 'white' : 'black'"
          :icon="icon"
        ></v-icon>
      </v-avatar>
    </template>
    <template #append>
      <v-icon icon="mdi-chevron-right"></v-icon>
    </template>
  </v-list-item>
</template>

<script>
import { useTheme } from "vuetify";

export default {
  name: "ProfileSettingsItem",
  setup() {
    const theme = useTheme();
    let mode = theme.global.current.value.dark;
    return { mode };
  },
  props: {
    disabled: Boolean,
    title: String,
    icon: String,
    link: String,
  },
};
</script>

<style scoped></style>
