<template>


  <GreetingUser ref="dashboard-greeting"/>
  <!--<div ref="dashboard-message" class="d-flex justify-center align-center" style="position:relative;min-height:30vh" >
    <DashboardMessage/>
    <v-img width="60%" class="ma-10" alt="Vue logo" :src="require('@/assets/logo.png')"/>
  </div>-->
  <v-container  class="bg-secondary">
    <!--
    <div ref="dashboard-performance">
    <h3 class="text-left mb-5">Deine Performance</h3>
  <YourPerformance/>
    </div>-->
    <div ref="dashboard-objects">
    <h3 class="text-left mb-5 mt-10">Deine Immobilien</h3>
    <ObjectCaroussel/>
    </div>
    <!--<div>
    <h3 class="text-left mb-5 mt-10">Die Bestenliste</h3>
    <DashboardRanking/>
    </div>
    <h3 class="text-left mb-5 mt-10">Deine Tägliche Belohnung</h3>
    <DailyRewardDashboard/>
  -->
    <h3 class="text-left mt-10">Weiterbildung</h3>
    <LatestCourse/>

    <v-sheet width="100%" style="position:relative" class=" my-3 my-md-0 rounded-xl" color="blue">
      <YoutubePlayer height="240"  margin="my-0" video-url="https://youtu.be/ViCV7gc5FNs?si=sa2hhz2YGQwYM7Xk"/>
    </v-sheet>
  </v-container>
</template>

<script>

import YourPerformance from "@/components/dashboard/YourPerformance";
import ObjectCaroussel from "@/components/objects/ObjectCaroussel";
import DashboardRanking from "@/components/dashboard/DashboardRanking";
import DailyRewardDashboard from "@/components/dashboard/DailyRewardDashboard";
import DashboardMessage from "@/components/dashboard/DashboardMessage";
import GreetingUser from "@/components/dashboard/GreetingUser";
import LatestCourse from "@/components/dashboard/LatestCourse";
import YoutubePlayer from "@/components/YoutubePlayer.vue";
export default {
  name: 'HomeViewMobile',
  data(){
    return{
      tour:null
    }
  },
  methods:{

    },

mounted(){
},
  components: {
    YoutubePlayer,
    LatestCourse,
    GreetingUser,
    DashboardMessage,
    DailyRewardDashboard,
    DashboardRanking,
    ObjectCaroussel,
    YourPerformance
  }
}
</script>
