import { tr as vtr } from "vuetify/locale";

export const tr = {
  $vuetify: {
    ...vtr,
  },
  theme: {
    dark: "Koyu Tema",
    light: "Aydınlık Mod",
  },
  refer: "Emlak tavsiye et",
  referHint2: "Yakında geliyor!",
  business: "İşletme",
  welcome: "EstateBoost'a Hoş Geldiniz!",
  private: "Bireysel",
  password: "Şifre",
  required: "Lütfen bu alanı doldurun",
  personalInformation: "Kişisel Bilgiler",
  readMore: "Daha Fazla Oku",
  auth: {
    loginWithLinkedIn: "LinkedIn ile Giriş Yap",
    loginWithSocials: "Sosyal Medya ile Giriş Yap",
    orUserNameAndPassword: "veya Kullanıcı Adı ve Şifre",
    alreadyHaveAccount: "Zaten bir hesabınız var mı? Buradan giriş yapın.",
    inputPassword: "Şifreyi girin",
    repeatPassword: "Şifreyi tekrar girin",
    password: {
      requirements: {
        title: "Şifreniz aşağıdaki gereksinimleri karşılamalıdır",
        oneNumber: "En az bir rakam içermelidir",
        oneSpecialChar: "En az bir özel karakter içermelidir",
        oneChar: "En az bir büyük/küçük harf içermelidir",
        length: "En az 8 karakter uzunluğunda olmalıdır"
      }
    },
    forgotPassword: "Şifrenizi mi unuttunuz?",
    createAccount: "Hesap Oluştur",
    register: {
      title: "Kayıt Ol",
      privacy: "{agb} & {privacy} belgelerini okudum ve kabul ediyorum.",
      privacyError:
        "Kayıt olabilmek için gizlilik ve şartlar politikalarını kabul etmelisiniz.",
      business: {
        checkbox: "KDV yükümlülüğüm var",
        name: "Şirket Adı",
        legalForm: "Hukuki Statü",
        uStIdNr: "KDV Kimlik Numarası",
        hint: "Kimler KDV yükümlüdür?",
        hintText:
          "Almanya'da yıllık 22.000 € üzeri ciroya sahip işletmeler KDV ödemek zorundadır. Bu sınırın altındaki işletmeler küçük işletme olarak sınıflandırılır."
      },
      businessOrPrivate:
        "Kişisel mi yoksa bireysel olarak mı kayıt olmak istiyorsunuz?",
      businessOrPrivateHint:
        "EstateBoost, işletmeler ve bireyler için özelleştirilmiş özellikler sunar."
    }
  },
  points: "Puan | Puanlar",
  rank: "Sıra",
  calculatedReferralFee: "tahmini yönlendirme ücreti",
  profile: {
    share: {
      text:
        "Bu senin kişisel profil bağlantın. Bu bağlantı rastgele oluşturulmuş bir anahtar ile korunmaktadır, bu nedenle yalnızca bu bağlantıya sahip olan kişiler profilini görüntüleyebilir.\n\n    Yeniden oluştur düğmesine tıkladığında, eski bağlantı geçerliliğini yitirir.",
      generate: "Yeniden oluştur",
      share: "Profilini Paylaş",
      sharetext: "Profilimi EstateBoost'ta ziyaret et."
    }
  },
  achievements: {
    empty: "Başka başarı yok"
  },
  rewards: {
    unclaimed: "Talep Edilmemiş",
    claimed: "Talep Edilmiş"
  },
  dashboard: {
    your_properties: "Mülkleriniz",
    ranking: "Sıralama",
    education: "Eğitim",
    to_wallet: "Cüzdana Git",
    compareValue: "Karşılaştırma değeri her zaman ayın 1'inde"
  },
  dailyreward: {
    button: "Günlük Bonus",
    hint: "Günde sadece bir kez talep edebilirsiniz.",
    claim: "Talep Et",
    thanks: "Teşekkürler",
    schade: "Yazık"
  },
  time: {
    days: "Günler",
    hours: "Saatler",
    minutes: "Dakikalar",
    seconds: "Saniyeler"
  },
  confirm_email: {
    text: "E-postanız henüz doğrulanmadı.",
    button: "Şimdi Doğrula"
  },
  verification: {
    failureReason: "Başarısızlık nedeni"
  },
  enum: {
    pending: "Beklemede",
    EVerificationState: {
      ANGERUFEN: "Zaten arandı",
      NICHT_DURCHGEKOMMEN: "Ulaşılamadı",
      WARTE_AUF_RUECKMELDUNG: "Geri dönüş bekleniyor",
      VERIFIZIERUNG_FEHLGESCHLAGEN: "Doğrulama başarısız",
      VERIFIZIERUNG_LÄUFT: "Doğrulama beklemede"
    },
    bToCState: {
      PENDING: "Beklemede",
      SUCCESS: "Başarılı",
      COINS_COLLECTED: "Coinler toplandı",
      ARCHIVED: "Arşivlendi",
      CANCELED: "İptal edildi"
    },
    gkProcess: {
      SEND_TEASER_EXPOSE: "Tanıtım/Sunum gönder",
      AWAIT_APPROVAL: "Onay bekle",
      ADD_PROPERTY_IN_ESTATEBOOST: "EstateBoost'ta mülk ekle",
      VERIFY_INPUT: "Girişi onayla",
      CREATE_EXPOSE_DATA_ROOM: "Gayrimenkul tanıtım dosyası oluştur",
      EVALUATE_PROPERTY: "Gayrimenkulü değerlendir",
      APPROVE_EVALUATION: "Değerlendirmeyi onayla",
      OPEN_DUE_DILIGENCE_PROCESS: "Due Diligence sürecini başlat",
      INITIATE_MARKET_CONTACT: "Pazarlama sürecini başlat",
      MAKE_BINDING_OFFER: "Bağlayıcı teklif ver",
      COMPLETE_NOTARIZATION: "Noter sürecini tamamla",
      RECEIVE_EXTERNAL_COMMISSION: "Harici komisyonu tahsil et",
      PAY_TIP_PROVIDER_COMMISSION: "Tavsiye komisyonu ödeniyor",
      ANALYZE_PROPERTY_DATA: "Gayrimenkul verilerini analiz et",
      CONDUCT_MARKET_ANALYSIS: "Pazar analizini tamamla",
      SELECT_EVALUATION_METHOD: "Değerlendirme yöntemi seç",
      INTERNAL_VALIDATION: "Dahili doğrulama",
      APPROVE_COMMUNICATION: "İletişim bilgisini onayla",
      PREPARE_NEGOTIATION_STRATEGY: "Müzakere planını oluştur",
      START_NEGOTIATIONS: "Müzakere sürecini başlat",
      CONDUCT_NEGOTIATIONS: "Müzakere sürecini yönet",
      ADJUST_CONTRACT_TERMS: "Sözleşme koşullarını güncelle",
      FINALIZE_AND_SIGN_CONTRACT: "Sözleşmeyi tamamla ve imzala"
    },
    gkProcessState: {
      OPEN: "Açık",
      IN_PROGRESS: "İşlemde",
      DONE: "Tamamlandı"
    },
    objectType: {
      TYPE_BAUERNHOF: "Çiftlik",
      TYPE_EINFAMILIENHAUS: "Müstakil Ev",
      TYPE_MEHRFAMILIENHAUS: "Apartman",
      TYPE_WOHNUNG: "Daire",
      TYPE_GEWERBEFLAECHE: "Ticari Alan",
      TYPE_LAGERHALLE: "Depo",
      TYPE_GRUNDSTUECK_WOHNEN: "Konut Arsası",
      TYPE_GEWERBE: "Ticari",
      TYPE_MIXED_USE: "Karma Kullanım",
      TYPE_RESIDENTIAL_MFH: "Çok Aileli Konut",
      TYPE_RESIDENTIAL_EFH: "Tek Aileli Konut",
      TYPE_RESIDENTIAL_DHH: "İkiz Ev",
      TYPE_RESIDENTIAL_ETW: "Mülk Daire",
      TYPE_RESIDENTIAL_WOHN_GESCHAEFTSHAUS: "Konut-İşyeri Binası",
      TYPE_RETAIL: "Perakende",
      TYPE_HOSPITALITY: "Konaklama",
      TYPE_HEALTHCARE: "Sağlık",
      TYPE_OFFICE: "Ofis",
      TYPE_LOGISTIK: "Lojistik",
      TYPE_LIGHT_INDUSTRIAL: "Hafif Sanayi",
      TYPE_INFRASTRUCTURE: "Altyapı",
      TYPE_ENERGY_INFRASTRUCTURE: "Enerji Altyapısı"
    }
  },
  tasks: "Görev | Görevler",
  notification: {
    title: "Bildirimler",
    deleteAll: "Hepsini sil",
    noData: "Bildirim yok"
  },
  wallet: {
    token: "Token | Tokenlar",
    coin: "Coin | Coinler"
  },
  education: {
    to: "Eğitime Git"
  },
  files: {
    download: "Dosyayı indir",
    upload: "Yeni dosya yükle"
  },
  process: {
    coinFormula:
      "Bu değer, bir mülk sahibine verdiğiniz ipucu sayesinde onunla bir komisyonculuk sözleşmesi kurulmasından dolayı alacağınız ücreti temsil eder. Bu ücret, satış fiyatına bağlı olan komisyon üzerinden hesaplanır. Satıcının komisyon payındaki (iç komisyon) hisseniz şöyledir:",
    ownerConfirmation: {
      title: "Mal Sahibi Onay Beyanı",
      text:
        "Mal sahibinin onayı, sürecin en önemli parçasıdır.<br/>EstateBoost, <b>şeffaflık</b> ve <b>dürüstlük</b> ilkesini benimser ve yalnızca <b>gönüllü</b> ve <b>mal sahibi tarafından bilerek sağlanan</b> verileri toplar.",
      alert:
        "Özellikle vurguluyoruz ki, şirket olarak yasal olarak paylaşılması mümkün olmayan verilerle ilgilenmiyoruz.*",
      footNote: "*Herhangi bir kötüye kullanım hesap askıya alma ile sonuçlanır.",
      confirm:
        "Bu platformda mal sahibinin kişisel verilerini paylaşmak için kendisinden onay aldığımı burada teyit ediyorum.",
      download: "PDF şablonunu buradan indir",
      upload: "Yeni onay beyanı yükle",
      noConfirmation: "Yüklenmiş bir onay beyanı yok",
      noConfirmation2: "Onay verilmedi."
    },
    objectData: {
      title: "Nesne Verilerini Gir"
    },
    verification: {
      title: "Doğrulama",
      text:
        "Nesneniz artık doğrulamaya hazır.<br/>Lütfen girdiklerinizi tekrar gözden geçirin.",
      warning:
        "Doğrulamayı gönderdikten sonra nesne verilerini daha fazla düzenleyemezsiniz.",
      button: "Şimdi doğrula",
      running:
        '<p>Doğrulama devam ediyor. Mal sahibine ulaştığımızda ve verilerinizi doğruladığımızda sizi bilgilendireceğiz.</p><p>Güncel durum:<br/> <small class="text-accent" v-html="state.message"></small></p>'
    },
    contract: {
      title: "Komisyon Sözleşmesi Tamamlandı",
      text:
        'Mal sahibi ile yapılan komisyon sözleşmesi <span class="success">aktif</span>.<br/><v-chip >{date}</v-chip> tarihine kadar "sizin" mülkünüzü pazarlama süremiz var.'
    },
    marketing: {
      title: "Pazarlama"
    },
    notaryAppointment: {
      title: "Noter Randevusu"
    },
    provision: {
      title: "Komisyon Alındı"
    },
    collect: {
      title: "Coinleri Topla",
      text:
        "Artık coinlerinizi toplayabilirsiniz! Ayrıca coinlerinizi Euro'ya çevirerek kayıtlı hesabınıza çekebilirsiniz.",
      alreadyCollected: "Coinlerinizi zaten topladınız."
    },
    overallProgress: "Genel İlerleme",
    collectCoins: "Coinleri Şimdi Topla",
    success: "Başarılı",
    reward: "Coin Ödülü"
  },
  topBar: {
    saveToHomescreen: "Web Uygulamasını ana ekranınıza kaydedin!",
    apple:
      "iPhone/iPad: Paylaş düğmesine dokunun ve 'Ana Ekrana Ekle' seçeneğini seçin.",
    android: "Üç noktaya dokunun ve 'Ana Ekrana Ekle'yi seçin."
  },
  greeting: {
    morning: "Günaydın",
    lunch: "Öğle Yemeği Zamanı",
    evening: "İyi Akşamlar",
    default: "İyi Günler"
  },
  buttons: {
    buy: "Satın Al",
    close: "Kapat",
    save: "Kaydet",
    send: "Gönder",
    continue: "Devam",
    cancel: "İptal",
    open: "Aç"
  },
  bankDetails: {
    IBAN: "IBAN",
    BIC: "BIC",
    bankName: "Banka Adı",
    accountHolder: "Hesap Sahibi"
  },
  user: {
    level: "Seviye",
    profile: {
      tipster: "İpucu Veren",
      checkEducation:
        "*Bir mülk eklemek için gizlilik eğitimimizi tamamlamalısınız."
    },
    registeredAt: "Üyelik tarihi",
    firstName: "Ad",
    lastName: "Soyad",
    email: "E-posta",
    phone: "Telefon Numarası",
    mobile: "Cep Telefonu",
    bio: "Biyografi",
    birthDate: "Doğum Tarihi",
    messages: {
      emailVerified: "E-posta adresiniz doğrulandı.",
      emailNotVerified:
        "Uygulamayı tam olarak kullanmak için e-postanızı doğrulamalısınız.<br/><b>E-postayı Şimdi Doğrula</b>ya tıklayın, size bir e-posta göndereceğiz.",
      validateBtn: "E-postayı Şimdi Doğrula.",
      changeBirthDate: "Doğum Tarihini Güncelle"
    }
  },
  address: {
    title: "Adres Bilgileri",
    titleSingle: "Adres",
    edit: "Adresi düzenle",
    streetAndNumber: "Sokak & Bina Numarası",
    showOnMap: "Haritada göster",
    save: "Yeni adres ekle",
    street: "Sokak",
    houseNumber: "Bina Numarası",
    zip: "Posta Kodu",
    city: "Şehir",
    bundesland: "Eyalet",
    country: "Ülke"
  },
  yourObjects: "Mülkleriniz",
  allObjects: "Tüm Mülkler",
  form: {
    search: "Ara",
    invalidDate: "Geçersiz Tarih"
  },
  noData: {
    default: "Veri bulunamadı",
    reactive: "Hiç {obj} bulunamadı",
    small: "Hiç {obj} yok"
  },
  object: {
    owner: {
      title: "Mülk Sahibi",
      fullName: {
        title: "Mal Sahibinin Tam Adı",
        hint:
          "Lütfen bu verileri mal sahibinin onayı olmadan paylaşmanın yasak olduğunu unutmayın."
      },
      email: "Mal Sahibinin E-postası",
      telephone: "Mal Sahibinin Telefon Numarası",
      error: {
        importantData: "Önemli veriler eksik!",
        nameMissing: "Mal sahibinin adı eksik.",
        emailMissing: "Mal sahibinin e-postası eksik.",
        phoneMissing: "Mal sahibinin telefon numarası eksik."
      }
    },
    sale: "SATIŞ",
    retirement: "EMEKLİLİK",
    slots: {
      locked: "Kilitli Slot",
      unlock: "Slotu Aç",
      buy: {
        confirm:
          "OK'e tıklayarak {token} token düşülecek ve 1 slot eklenecek.",
        title:
          "Daha fazla mülk eklemek için yeni bir slot satın alın.",
        insufficientSales:
          "Yeni slotları açmak için en az 2 mülk satmalısınız.",
        commercialActivity:
          "Yılda üçüncü mülkten itibaren ticari faaliyet sayılır. Lütfen gerekirse bir işletme kaydı yapmanız gerekebileceğini unutmayın.",
        priceCalculation:
          "Fiyat, son tamamladığınız ipuçlarına göre hesaplanır."
      }
    },
    saled: "satıldı",
    object: "Nesne | Nesneler",
    estate: "Mülk | Mülkler",
    price: "Fiyat",
    istFactor: "Verim Faktörü / Net Getiri",
    usableArea: "Kullanılabilir Alan",
    flatArea: "Yaşam Alanı",
    baseArea: "Arsa Alanı",
    tooltip: "Beklenen yönlendirme ücreti",
    yearOfConstruction: "Yapım Yılı",
    onlineSince: "Yayına Alınma Tarihi",
    contacts: "İrtibat Kişisi",
    selectContact: "İrtibat Kişisi Seç",
    pictures: "Resimler",
    add: "Mülk Ekle",
    expected: "tahmini",
    actual: "gerçek",
    provision: "Komisyon",
    expectedProvision: "tahmini komisyon",
    fairValue: "Gerçek Piyasa Değeri",
    fairValueExpected: "Tahmini Piyasa Değeri",
    fairValueExpectedExplanation:
      "Tahmini piyasa değeri, çeşitli faktörlere göre hesaplanır ve satış fiyatından farklı olabilir."
  },
  settingsPopup: {
    admin: {
      name: "yönetici",
      title: "Yönetim Alanı",
      objects: {
        title: "Mülkler",
        subtitle: "Mülklerinizi yönetin",
        verification: {
          title: "Doğrulama",
          whyError: "Doğrulama neden başarısız oldu?",
          whyErrorPlaceholder: "Nedeni buraya yazın...",
          whyErrorLabel: "Başarısızlık Nedeni",
          send: "Gönder",
          cancel: "İptal",
          declarationOfConsent: "Mal Sahibi Onay Beyanı",
          sellType: "İlgilendiği Konu",
          objectType: "Mülk Türü",
          livingArea: "Yaşam Alanı",
          baseArea: "Arsa Alanı",
          owner: "Mal Sahibi",
          createdBy: "Oluşturan: "
        },
        marketing: {
          title: "Pazarlama",
          edit: "Düzenle",
          view: "Görüntüle"
        },
        success: "Başarılı",
        error: "Hata",
        edit: {
          brokerCommissionGk: {
            title: "Broker Komisyonu GK",
            fairValue: "Sözleşme Öncesi Piyasa Değeri",
            salesValue: "Satış Değeri",
            provision: "Komisyon %",
            tipgiverProvision: "İpucu Veren Komisyonu %",
            brokerCommission: "Broker Komisyonu %",
            closeBtn: "Kapat"
          },
          userView: "Kullanıcı Görünümü",
          overview: {
            title: "Genel Bakış"
          },
          agentContract: {
            title: "Broker Sözleşmesi",
            dateEnd: "Sözleşme Bitiş Tarihi",
            signedCheckbox: "Sözleşme İmzalı",
            loadingBtn: "Sözleşme Nesnesi Oluştur",
            saveBtn: "Kaydet"
          },
          marketing: {
            title: "Pazarlama",
            date: "Noter Randevusu: ",
            successCheckbox: "Mülk Pazarlanmış",
            saveBtn: "Kaydet",
            loadingBtn: "Noter Randevusu Nesnesi Oluştur"
          },
          payment: {
            title: "Ödeme",
            saveBtn: "Mülkü Kullanıcı İçin Tamamla",
            infoText1: "*Kullanıcı şimdi coinlerini toplayabilir",
            infoText2: "Kullanıcı şimdi coinlerini çekebilir"
          },
          fairValue: "Piyasa Değeri",
          provision: "Komisyon",
          tipGiver: "İpucu Veren"
        }
      },
      trainingCenter: {
        title: "Eğitim ve Destek Merkezi",
        subtitle: "Eğitimleri Düzenle ve Ekle",
        courseSection: {
          noData: "Hiç bölüm bulunamadı",
          addBtn: "Ekle",
          addTitle: "Başlık",
          addDescription: "Açıklama",
          addChapter: "Bölüm",
          addSaveBtn: "Yeni Kurs Yükle"
        }
      },
      payout: {
        title: "Ödemeler",
        subtitle: "Tüm Ödemeler"
      },
      statistics: {
        title: "İstatistikler",
        subtitle: "İstatistikler"
      }
    },
    gkAdmin: {
      name: "Ticari Müşteri Yönetimi",
      details: {}
    },
    accountDetails: {
      name: "Hesap Bilgileri",
      details: {}
    },
    address: {
      name: "Adres Bilgileri",
      details: {}
    },
    bankDetails: {
      name: "Banka Hesap Bilgileri",
      details: {}
    },
    shareProfile: {
      name: "Profilini Paylaş",
      details: {}
    },
    settings: {
      name: "Genel Ayarlar",
      details: {}
    },
    changePassword: {
      name: "Şifreni Güncelle",
      details: {
        newPassword: "Yeni Şifre",
        confirmPassword: "Şifreyi Onayla",
        oldPassword: "Mevcut Şifre",
        change: "Şifreyi Değiştir",
        validation: {
          title: "Şifre şu gereksinimleri karşılamalı:",
          length: "En az 8 karakter",
          number: "En az bir rakam",
          specialChar: "En az bir özel karakter",
          char: "En az bir büyük/küçük harf",
          match: "Şifreler eşleşmiyor",
          required: "Zorunlu alan"
        }
      }
    },
    achievements: {
      name: "Başarı Geçmişi",
      details: {}
    },
    trainingCenter: {
      name: "Eğitim ve Destek Merkezi",
      details: {}
    },
    docTemplates: {
      name: "Belge ve Şablonlar",
      details: {}
    },
    logout: "Oturumu Kapat"
  },
  filter: {
    headline: "Filtre",
    deleteAll: "Tüm Filtreleri Sil",
    sort: {
      by: "Sırala",
      price: {
        desc: "En Yüksek Fiyat",
        asc: "En Düşük Fiyat"
      },
      progress: {
        desc: "En yüksek ilerleme",
        asc: "En düşük ilerleme"
      },
      area: {
        desc: "En Büyük Arsa Alanı",
        asc: "En Küçük Arsa Alanı"
      },
      usableArea: {
        desc: "En Büyük Kullanılabilir Alan",
        asc: "En Küçük Kullanılabilir Alan"
      },
      yearOfConstruction: {
        desc: "En Yeni Yapım Yılı",
        asc: "En Eski Yapım Yılı"
      },
      returnOfInvestment: {
        desc: "En Yüksek ROI",
        asc: "En Düşük ROI"
      },
      onlineSince: {
        desc: "En Yeni İlan",
        asc: "En Eski İlan"
      }
    },
    min: "Min",
    max: "Maks",
    found: {
      init: "henüz nesne yok",
      object: "Obje(ler) bulundu"
    },
    geschaeftskunden: "Ticari müşteri"
  },
  common: {
    uploadImages: "Resim Yükle",
    save: "Kaydet",
    cancel: "İptal",
    close: "Kapat",
    images: "Resimler",
    messages: {
      percentError: "Lütfen 0 ile 100 arasında bir değer girin",
      imageUploadSuccess: "Resimler başarıyla yüklendi",
      imageUploadError: "Resim yükleme hatası",
      imageDeleteSuccess: "Resim başarıyla silindi",
      imageDeleteError: "Resim silme hatası",
      updateSuccess: "Başarıyla güncellendi",
      updateError: "Güncelleme hatası"
    }
  },
  tour: {
    startTooltip: "Tour başlat",
    next: "İleri",
    prev: "Geri",
    done: "Tamam",
    steps: {
      objectOverview: {
        title: "Nesne Genel Bakışı",
        intro:
          "Burada sizin için şu anda pazarda temsil ettiğimiz tüm yönlendirilen anlaşmalar bulunmaktadır. Beklenen yönlendirme ücretinizi yeşil renkte ve mevcut durumu turuncu renkte görebilirsiniz. Potansiyel anlaşmanın detaylarını görmek için herhangi birine tıklayabilirsiniz."
      },
      search: {
        title: "Arama",
        intro:
          "Nesnelerinizi daraltmak veya belirli birini bulmak için anahtar kelimelerle arama yapabilirsiniz."
      },
      filter: {
        title: "Filtre",
        intro: "Sonuçları filtreleyebilir..."
      },
      sort: {
        title: "Sıralama",
        intro: "ve tercih ettiğiniz sırada düzenleyebilirsiniz."
      },
      feedback: {
        title: "Geri Bildirim",
        intro:
          "Samimi geri bildiriminiz bizim için önemlidir. Lütfen EstateBoost'u nasıl geliştirebileceğimizi bize bildirin."
      }
    }
  }
};
