<script>
import PaginationWrapper from "@/components/PaginationWrapper.vue";
import GKObjectItem from "@/components/geschaeftskunde/GKObjectItem.vue";
import { getFilterOptions } from "@/services/filter.service";
import FilterItem from "@/views/geschaeftskunde/FilterItem.vue";
import FilterMenu from "@/views/geschaeftskunde/FilterMenu.vue";
import { fixed } from "lodash/fp/_falseOptions";
import ENumberFormat from "@/enums/ENumberFormat";
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import { useDisplay } from "vuetify";
import GeschaeftskundenFilter from "@/components/filter/GeschaeftskundenFilter.vue";
import GKObjectListItem from "@/components/geschaeftskunde/GKObjectListItem.vue";

export default {
  name: "AdminAllObjects",
  computed: {
    ENumberFormat() {
      return ENumberFormat;
    },
  },
  methods: {
    updateStep(object, step) {
      object.progressStep = step.progressStep;
    },
    fixed() {
      return fixed;
    },
    removeAllFilters() {
      this.filter.filter = {
        priceRange: [0, null],
        usableAreaRange: [0, null],
        areaRange: [0, null],
        yearOfConstructionRange: [0, null],
        returnOfInvestmentRange: [0, null],
      };
      this.filter.geschaeftskundenId = null;
    },
    getSortItems() {
      return [
        {
          text: this.$t("filter.sort.price.desc"),
          value: {
            sortBy: "s.value",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.price.asc"),
          value: {
            sortBy: "s.value",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.area.desc"),
          value: {
            sortBy: "landArea",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.area.asc"),
          value: {
            sortBy: "landArea",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.usableArea.desc"),
          value: {
            sortBy: "usableArea",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.usableArea.asc"),
          value: {
            sortBy: "usableArea",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.returnOfInvestment.desc"),
          value: {
            sortBy: "returnOfInvestment",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.returnOfInvestment.asc"),
          value: {
            sortBy: "returnOfInvestment",
            order: "ASC",
          },
        },

        {
          text: this.$t("filter.sort.yearOfConstruction.desc"),
          value: {
            sortBy: "yearOfConstruction",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.yearOfConstruction.asc"),
          value: {
            sortBy: "yearOfConstruction",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.progress.asc"),
          value: {
            sortBy: "progressGk",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.progress.desc"),
          value: {
            sortBy: "progressGk",
            order: "DESC",
          },
        },
      ];
    },
  },
  watch: {
    "$i18n.locale"() {
      this.sortItems = this.getSortItems();
    },
  },
  setup() {
    const { mobile } = useDisplay();
    return { mobile };
  },
  components: {
    ReadableNumber,
    FilterMenu,
    FilterItem,
    GKObjectItem,
    PaginationWrapper,
    GeschaeftskundenFilter,
    GKObjectListItem,
  },
  async created() {
    let filter = await getFilterOptions();
    if (filter && filter.filter) this.fixedFilter = filter.filter;
  },
  data() {
    return {
      sortItems: this.getSortItems(),
      tab: "cards",
      fixedFilter: {
        priceRange: [0, null],
        usableAreaRange: [0, null],
        areaRange: [0, null],
        yearOfConstructionRange: [null, null],
        returnOfInvestmentRange: [0, null],
      },
      filter: {
        geschaeftskundenId: null,
        search: "",
        filter: {
          priceRange: [0, null],
          usableAreaRange: [0, null],
          areaRange: [0, null],
          yearOfConstructionRange: [null, null],
          returnOfInvestmentRange: [0, null],
        },
        sort: {
          sortBy: "progressGk",
          order: "DESC",
        },
      },
    };
  },
};
</script>

<template>
  <pagination-wrapper :filter="filter" url="object/gk/list/page/">
    <template #filter="{ filter, totalItems }">
      <div class="d-flex" style="gap: 10px">
        <v-text-field
          density="compact"
          append-inner-icon="mdi-magnify"
          hide-details
          v-model="filter.search"
          :label="$t('form.search') + '...'"
        />
        <GeschaeftskundenFilter
          v-if="!mobile"
          v-model="filter.geschaeftskundenId"
        />

        <div class="d-flex" v-if="!mobile" style="gap: 10px">
          <FilterMenu
            :label="$t('object.price')"
            prefix="€"
            :number-format="ENumberFormat.CURRENCY"
            :fixed-min-max="fixedFilter.priceRange"
            v-model="filter.filter.priceRange"
          />
          <FilterMenu
            prefix="m²"
            :label="$t('object.usableArea')"
            :fixed-min-max="fixedFilter.usableAreaRange"
            v-model="filter.filter.usableAreaRange"
          />
          <FilterMenu
            prefix="m²"
            :label="$t('object.baseArea')"
            :fixed-min-max="fixedFilter.areaRange"
            v-model="filter.filter.areaRange"
          />
          <FilterMenu
            :label="$t('object.yearOfConstruction')"
            :number-format="ENumberFormat.PLAIN"
            :fixed-min-max="fixedFilter.yearOfConstructionRange"
            v-model="filter.filter.yearOfConstructionRange"
          />
          <FilterMenu
            :label="$t('object.istFactor')"
            :fixed-min-max="fixedFilter.returnOfInvestmentRange"
            v-model="filter.filter.returnOfInvestmentRange"
          />
        </div>
        <v-dialog max-width="500px">
          <template #activator="{ props }">
            <v-btn
              v-if="mobile"
              variant="plain"
              color="accent"
              icon
              v-bind="props"
              ><v-icon>mdi-filter</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-title>{{ $t("filter.headline") }}</v-card-title>
            <v-card-subtitle
              class="float-left text-disabled text-left mb-2 d-flex flex-column align-start"
              style="gap: 5px; font-size: 0.9rem"
            >
              <span>
                <ReadableNumber :number="totalItems"></ReadableNumber>
                <span v-text="$t('filter.found.object', totalItems)"></span>
              </span>
              <span class="d-flex align-center">
                <span v-text="$t('filter.sort.by')"></span>
                <v-select
                  style="font-size: 0.9rem"
                  variant="plain"
                  class="ml-2 full-dense-input text-text"
                  density="compact"
                  hide-details
                  v-model="filter.sort"
                  :items="sortItems"
                  item-title="text"
                  item-value="value"
                />
              </span>
              <v-btn @click="removeAllFilters" class="px-0" variant="text"
                ><v-icon>mdi-close</v-icon> {{ $t("filter.deleteAll") }}</v-btn
              >
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <GeschaeftskundenFilter v-model="filter.geschaeftskundenId" />
                </v-col>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.priceRange[0]"
                    :max="fixedFilter.priceRange[1]"
                    prefix="€"
                    :label="$t('object.price')"
                    v-model="filter.filter.priceRange"
                  />
                </v-col>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.usableAreaRange[0]"
                    :max="fixedFilter.usableAreaRange[1]"
                    :label="$t('object.usableArea')"
                    v-model="filter.filter.usableAreaRange"
                  />
                </v-col>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.areaRange[0]"
                    :max="fixedFilter.areaRange[1]"
                    :label="$t('object.baseArea')"
                    v-model="filter.filter.areaRange"
                  />
                </v-col>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.yearOfConstructionRange[0]"
                    :max="fixedFilter.yearOfConstructionRange[1]"
                    :label="$t('object.yearOfConstruction')"
                    v-model="filter.filter.yearOfConstructionRange"
                  />
                </v-col>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.returnOfInvestmentRange[0]"
                    :max="fixedFilter.returnOfInvestmentRange[1]"
                    :label="$t('object.istFactor')"
                    v-model="filter.filter.returnOfInvestmentRange"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template #default="{ items, totalItems, loading }">
      <div class="d-flex flex-column align-start">
        <div
          style="width: 100%"
          class="d-flex align-center justify-space-between"
        >
          <div
            class="float-left text-disabled align-center py-2 d-flex flex-wrap flex-md-nowrap align-center"
            :style="{ gap: mobile ? '0' : '25px' }"
            style="gap: 10px; font-size: 0.9rem"
          >
            <v-tabs v-model="tab" hide-slider selected-class="bg-grey-darken-3">
              <v-tab value="cards" tile class="v-elephant"
                ><v-icon>mdi-view-grid</v-icon></v-tab
              >
              <v-tab value="list" class="v-elephant"
                ><v-icon>mdi-format-list-bulleted</v-icon></v-tab
              >
            </v-tabs>
            <span>
              <ReadableNumber
                class="mr-1"
                :number="totalItems"
              ></ReadableNumber>
              <span v-text="$t('filter.found.object', totalItems)"></span>
            </span>
            <span class="d-flex align-center">
              <span v-text="$t('filter.sort.by')"></span>
              <v-select
                style="font-size: 0.9rem"
                variant="plain"
                class="ml-2 fix-select-icon full-dense-input text-text"
                density="compact"
                hide-details
                v-model="filter.sort"
                :items="sortItems"
                item-title="text"
                item-value="value"
              />
            </span>
          </div>
          <v-btn
            @click="removeAllFilters"
            density="compact"
            size="small"
            v-if="!mobile"
            variant="plain"
            ><v-icon>mdi-close</v-icon> {{ $t("filter.deleteAll") }}</v-btn
          >
        </div>
        <p v-if="!loading && items.length <= 0">
          {{ $t("noData.reactive", { obj: "Objekte" }) }}
        </p>
        <v-window v-model="tab" class="w-100">
          <v-window-item value="cards">
            <template v-if="loading">
              <div
                class="d-flex justify-start flex-wrap"
                style="gap: 10px; width: 100%"
              >
                <div style="width: calc(33.33% - 7px)" v-for="n in 9" :key="n">
                  <v-sheet class="px-5 py-5 text-left" rounded>
                    <v-skeleton-loader
                      type="list-item-two-line"
                    ></v-skeleton-loader>
                    <div class="d-flex">
                      <v-skeleton-loader
                        type="chip"
                        width="120"
                        class="me-2"
                      ></v-skeleton-loader>
                      <v-skeleton-loader
                        type="chip"
                        width="130"
                      ></v-skeleton-loader>
                    </div>
                  </v-sheet>
                </div>
              </div>
            </template>
            <template v-else>
              <div
                class="d-flex justify-start flex-wrap pa-1"
                style="gap: 10px"
              >
                <div style="flex-grow: 1" v-for="item of items" :key="item.id">
                  <GKObjectItem
                    @updatedStep="(t) => this.updateStep(item, t.step)"
                    :object="item"
                  />
                </div>
              </div>
            </template>
          </v-window-item>

          <v-window-item value="list">
            <template v-if="loading">
              <v-list class="pa-0 w-100" rounded>
                <v-sheet v-for="n in 9" :key="n" class="pa-4 mb-2" rounded>
                  <v-skeleton-loader
                    type="list-item-three-line"
                  ></v-skeleton-loader>
                </v-sheet>
              </v-list>
            </template>
            <template v-else>
              <div class="d-flex justify-start flex-wrap">
                <v-list class="pa-0 w-100" rounded>
                  <GKObjectListItem
                    v-for="(item, index) of items"
                    :key="item.id"
                    :object="item"
                    :is-last="index === items.length - 1"
                    @updatedStep="(t) => this.updateStep(item, t.step)"
                  />
                </v-list>
              </div>
            </template>
          </v-window-item>
        </v-window>
      </div>
    </template>
  </pagination-wrapper>
</template>

<style scoped>
.v-tab.v-tab.v-btn.v-elephant {
  min-width: 0px;
}
</style>
