<script>
import { useTheme } from "vuetify";

export default {
  name: "DarkModeSwitch",
  setup() {
    const theme = useTheme();
    function toggleTheme() {
      theme.global.name.value = theme.global.current.value.dark
        ? "light"
        : "dark";
      console.log("Theme toggled to", theme.global.current.value.dark ? "dark" : "light");
      localStorage.setItem("theme", theme.global.current.value.dark ? "dark" : "light");
    }

    return { toggleTheme };
  },
  props: {
    listItem: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    avatarColor() {
      return this.mode ? "white" : "black";
    },
    iconColor() {
      return this.mode ? "black" : "white";
    },
  },
};
</script>

<template>
  <v-list-item @click="toggleTheme" v-if="listItem">
    <template #title>
      <v-list-item-title>
        {{ $vuetify.theme.current.dark ? $t("theme.light") : $t("theme.dark") }}
      </v-list-item-title>
    </template>
    <template #prepend>
      <v-avatar
        :style="{ borderRadius: '5px' }"
        :class="$vuetify.theme.current.dark ? 'bg-black' : 'bg-white'"
      >
        <v-icon :color="$vuetify.theme.current.dark ? 'white' : 'black'">
          {{
            $vuetify.theme.current.dark
              ? "mdi-white-balance-sunny"
              : "mdi-weather-night"
          }}
        </v-icon>
      </v-avatar>
    </template>
    <template #append>
      <v-icon icon="mdi-chevron-right"></v-icon>
    </template>
  </v-list-item>
  <v-btn
    v-else
    icon
    :ripple="false"
    class="elevation-0 mr-3 theme-toggle"
    @click="toggleTheme"
    v-tooltip:left="{
      text: $vuetify.theme.current.dark
        ? 'Light Mode aktivieren'
        : 'Dark Mode aktivieren',
      openDelay: 500,
    }"
  >
    <v-icon :class="{ 'rotate-sun': $vuetify.theme.current.dark }">
      mdi-white-balance-sunny
    </v-icon>
    <v-icon :class="{ 'rotate-moon': $vuetify.theme.current.dark }">
      mdi-weather-night
    </v-icon>
  </v-btn>
</template>

<style scoped>
.theme-toggle {
  position: relative;
}

.v-icon {
  position: absolute;
  transition: all 0.7s ease;
}

/* Sonnen-Icon */
.v-icon:nth-child(1) {
  opacity: 1;
  transform: rotate(0);
}

.rotate-sun {
  transform: rotate(90deg) !important;
  opacity: 0 !important;
}

/* Mond-Icon */
.v-icon:nth-child(2) {
  opacity: 0;
  transform: rotate(-90deg);
}

.rotate-moon {
  transform: rotate(0) !important;
  opacity: 1 !important;
}
</style>
