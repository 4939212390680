<script>
import PaginationWrapper from "@/components/PaginationWrapper.vue";
import GKObjectItem from "@/components/geschaeftskunde/GKObjectItem.vue";
import { getFilterOptions } from "@/services/filter.service";
import FilterItem from "@/views/geschaeftskunde/FilterItem.vue";
import FilterMenu from "@/views/geschaeftskunde/FilterMenu.vue";
import { fixed } from "lodash/fp/_falseOptions";
import ENumberFormat from "@/enums/ENumberFormat";
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import { useDisplay } from "vuetify";
import introJs from "intro.js";
import { useAppStore } from "@/stores/app.module";
import GkTextSheet from "@/components/geschaeftskunde/GkTextSheet.vue";

export default {
  name: "GKAllObjects",
  computed: {
    emptyFilter() {
      if (!this.filter || !this.filter.filter) {
        return true;
      }
      let value = Object.values(this.filter.filter).every(
        (range) =>
          (range[0] === null || range[0] === 0) &&
          (range[1] === null || range[1] === 0)
      );
      return value;
    },
    ENumberFormat() {
      return ENumberFormat;
    },
    hasActiveFilters() {
      return Object.values(this.filter.filter).some(
        (range) => range[0] !== null || range[1] !== null
      );
    },
  },
  methods: {
    updateStep(object, step) {
      object.progressStep = step.progressStep;
    },
    fixed() {
      return fixed;
    },
    removeAllFilters() {
      this.filter.filter = {
        priceRange: [0, null],
        usableAreaRange: [0, null],
        areaRange: [0, null],
        yearOfConstructionRange: [0, null],
        returnOfInvestmentRange: [0, null],
      };
    },
    saveFilterInLocalStorage(filter) {
      localStorage.setItem("filter", JSON.stringify(filter));
    },
    getSortItems() {
      return [
        {
          text: this.$t("filter.sort.price.desc"),
          value: {
            sortBy: "s.value",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.price.asc"),
          value: {
            sortBy: "s.value",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.area.desc"),
          value: {
            sortBy: "landArea",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.area.asc"),
          value: {
            sortBy: "landArea",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.usableArea.desc"),
          value: {
            sortBy: "usableArea",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.usableArea.asc"),
          value: {
            sortBy: "usableArea",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.returnOfInvestment.desc"),
          value: {
            sortBy: "returnOfInvestment",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.returnOfInvestment.asc"),
          value: {
            sortBy: "returnOfInvestment",
            order: "ASC",
          },
        },

        {
          text: this.$t("filter.sort.yearOfConstruction.desc"),
          value: {
            sortBy: "yearOfConstruction",
            order: "DESC",
          },
        },
        {
          text: this.$t("filter.sort.yearOfConstruction.asc"),
          value: {
            sortBy: "yearOfConstruction",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.progress.asc"),
          value: {
            sortBy: "progressGk",
            order: "ASC",
          },
        },
        {
          text: this.$t("filter.sort.progress.desc"),
          value: {
            sortBy: "progressGk",
            order: "DESC",
          },
        },
      ];
    },
    startTour() {
      this.$nextTick(() => {
        const intro = introJs();
        intro.setOptions({
          steps: [
            {
              element: "#step1",
              title: this.$t("tour.steps.objectOverview.title"),
              intro: this.$t("tour.steps.objectOverview.intro"),
            },
            {
              element: "#step2",
              title: this.$t("tour.steps.search.title"),
              intro: this.$t("tour.steps.search.intro"),
            },
            {
              element: "#step3",
              title: this.$t("tour.steps.filter.title"),
              intro: this.$t("tour.steps.filter.intro"),
            },
            {
              element: "#step4",
              title: this.$t("tour.steps.sort.title"),
              intro: this.$t("tour.steps.sort.intro"),
            },
            {
              element: "#step5",
              title: this.$t("tour.steps.feedback.title"),
              intro: this.$t("tour.steps.feedback.intro"),
            },
          ],
          tooltipClass: "customTooltip",
          buttonClass: "customButton",
          showProgress: false,
          showBullets: true,
          exitOnEsc: true,
          exitOnOverlayClick: false,
          disableInteraction: true,
          nextLabel: this.$t("tour.next"),
          prevLabel: this.$t("tour.prev"),
          doneLabel: this.$t("tour.done"),
          hidePrev: true,
          overlayOpacity: 0.6,
        });
        intro.oncomplete(() => {
          this.appStore.toggleTour(false);
        });
        intro.start();
      });
    },
  },
  watch: {
    "$i18n.locale"() {
      this.sortItems = this.getSortItems();
    },
    "appStore.showTour"(newValue) {
      if (newValue) {
        this.startTour();
        this.appStore.toggleTour(false);
      }
    },
  },
  setup() {
    const { mobile } = useDisplay();
    const appStore = useAppStore();
    return { mobile, appStore };
  },
  components: {
    GkTextSheet,
    ReadableNumber,
    FilterMenu,
    FilterItem,
    GKObjectItem,
    PaginationWrapper,
  },
  async created() {
    let filter = await getFilterOptions();
    if (filter && filter.filter) this.fixedFilter = filter.filter;
    if (localStorage.getItem("filter")) {
      this.filter = JSON.parse(localStorage.getItem("filter"));
    }
  },
  data() {
    return {
      sortItems: this.getSortItems(),
      fixedFilter: {
        priceRange: [null, null],
        usableAreaRange: [null, null],
        areaRange: [null, null],
        yearOfConstructionRange: [null, null],
        returnOfInvestmentRange: [null, null],
      },
      filter: {
        search: "",
        filter: {
          priceRange: [null, null],
          usableAreaRange: [null, null],
          areaRange: [null, null],
          yearOfConstructionRange: [null, null],
          returnOfInvestmentRange: [null, null],
        },
        sort: {
          sortBy: "progressGk",
          order: "DESC",
        },
      },
    };
  },
};
</script>

<template>
  <pagination-wrapper
    @filter-changed="saveFilterInLocalStorage"
    :filter="filter"
    url="object/my/list/page/"
  >
    <template #filter="{ filter, totalItems }">
      <div class="d-flex" style="gap: 10px">
        <v-text-field
          id="step2"
          data-intro="search"
          density="compact"
          hide-details
          class="align-self-center"
          append-inner-icon="mdi-magnify"
          v-model="filter.search"
          :label="$t('form.search') + '...'"
        />
        <div
          class="d-flex"
          :id="$vuetify.display.mobile ? '' : 'step3'"
          v-if="!mobile"
          style="gap: 10px"
        >
          <FilterMenu
            :label="$t('object.price')"
            prefix="€"
            :number-format="ENumberFormat.CURRENCY"
            :fixed-min-max="fixedFilter.priceRange"
            v-model="filter.filter.priceRange"
          />
          <FilterMenu
            prefix="m²"
            :label="$t('object.usableArea')"
            :fixed-min-max="fixedFilter.usableAreaRange"
            v-model="filter.filter.usableAreaRange"
          />
          <FilterMenu
            prefix="m²"
            :label="$t('object.baseArea')"
            :fixed-min-max="fixedFilter.areaRange"
            v-model="filter.filter.areaRange"
          />
          <FilterMenu
            :label="$t('object.yearOfConstruction')"
            :number-format="ENumberFormat.PLAIN"
            :fixed-min-max="fixedFilter.yearOfConstructionRange"
            v-model="filter.filter.yearOfConstructionRange"
          />
          <FilterMenu
            :label="$t('object.istFactor')"
            :fixed-min-max="fixedFilter.returnOfInvestmentRange"
            v-model="filter.filter.returnOfInvestmentRange"
          />
        </div>
        <v-dialog max-width="500px">
          <template #activator="{ props }">
            <v-btn
              v-if="mobile"
              :id="$vuetify.display.mobile ? 'step3' : ''"
              variant="plain"
              color="accent"
              icon
              v-bind="props"
              ><v-icon>mdi-filter</v-icon></v-btn
            >
          </template>
          <v-card>
            <v-card-title>{{ $t("filter.headline") }}</v-card-title>
            <v-card-subtitle
              class="float-left text-disabled text-left d-flex flex-column align-start"
              style="gap: 5px; font-size: 0.9rem"
            >
              <span>
                <ReadableNumber
                  class="mr-1"
                  :number="totalItems"
                ></ReadableNumber>
                <span v-text="$t('filter.found.object', totalItems)"></span>
              </span>
              <span class="d-flex align-center">
                <span v-text="$t('filter.sort.by')"></span>
                <v-select
                  style="font-size: 0.9rem"
                  variant="plain"
                  class="ml-2 full-dense-input text-text"
                  density="compact"
                  hide-details
                  v-model="filter.sort"
                  :items="sortItems"
                  item-title="text"
                  item-value="value"
                />
              </span>
              <v-btn @click="removeAllFilters" class="px-0" variant="text"
                ><v-icon>mdi-close</v-icon>{{ $t("filter.deleteAll") }}</v-btn
              >
            </v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.priceRange[0]"
                    :max="fixedFilter.priceRange[1]"
                    prefix="€"
                    :label="$t('object.price')"
                    v-model="filter.filter.priceRange"
                  />
                </v-col>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.usableAreaRange[0]"
                    :max="fixedFilter.usableAreaRange[1]"
                    :label="$t('object.usableArea')"
                    v-model="filter.filter.usableAreaRange"
                  />
                </v-col>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.areaRange[0]"
                    :max="fixedFilter.areaRange[1]"
                    :label="$t('object.baseArea')"
                    v-model="filter.filter.areaRange"
                  />
                </v-col>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.yearOfConstructionRange[0]"
                    :max="fixedFilter.yearOfConstructionRange[1]"
                    :label="$t('object.yearOfConstruction')"
                    v-model="filter.filter.yearOfConstructionRange"
                  />
                </v-col>
                <v-col cols="12">
                  <FilterItem
                    :min="fixedFilter.returnOfInvestmentRange[0]"
                    :max="fixedFilter.returnOfInvestmentRange[1]"
                    :label="$t('object.istFactor')"
                    v-model="filter.filter.returnOfInvestmentRange"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <template #default="{ items, totalItems, loading }">
      <div class="d-flex flex-column align-start">
        <div
          style="width: 100%"
          class="d-flex align-center justify-space-between"
        >
          <div
            id="step4"
            class="float-left text-disabled py-2 d-flex flex-wrap flex-md-nowrap align-center"
            :style="{ gap: mobile ? '0' : '25px' }"
            style="gap: 10px; font-size: 0.9rem"
          >
            <span v-if="totalItems > 0 || !emptyFilter">
              <ReadableNumber
                class="mr-1"
                :number="totalItems"
              ></ReadableNumber>
              <span v-text="$t('filter.found.object', totalItems)"></span>
            </span>
            <span v-else v-text="$t('filter.found.init')"></span>
            <span class="d-flex align-center">
              <span v-text="$t('filter.sort.by')"></span>
              <v-select
                style="font-size: 0.9rem"
                variant="plain"
                class="ml-2 full-dense-input fix-select-icon text-text"
                density="compact"
                hide-details
                v-model="filter.sort"
                :items="sortItems"
                item-title="text"
                item-value="value"
              />
            </span>
          </div>
          <v-btn
            @click="removeAllFilters"
            density="compact"
            size="small"
            class="align-self-center"
            v-if="!mobile"
            variant="plain"
            :disabled="!hasActiveFilters"
            ><v-icon>mdi-close</v-icon> {{ $t("filter.deleteAll") }}</v-btn
          >
        </div>

        <template v-if="loading">
          <div
            class="d-flex justify-start flex-wrap"
            style="gap: 10px; width: 100%"
          >
            <div style="width: calc(33.33% - 7px)" v-for="n in 9" :key="n">
              <v-sheet class="px-5 py-5 text-left" rounded>
                <v-skeleton-loader
                  type="list-item-two-line"
                ></v-skeleton-loader>
                <div class="d-flex">
                  <v-skeleton-loader
                    type="chip"
                    width="120"
                    class="me-2"
                  ></v-skeleton-loader>
                  <v-skeleton-loader
                    type="chip"
                    width="130"
                  ></v-skeleton-loader>
                </div>
              </v-sheet>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="d-flex justify-start flex-wrap" style="gap: 10px">
            <GkTextSheet />
            <div style="flex-grow: 1" v-for="item of items" :key="item.id">
              <GKObjectItem
                @updatedStep="(t) => this.updateStep(item, t.step)"
                :object="item"
              />
            </div>
          </div>
        </template>
      </div>
    </template>
  </pagination-wrapper>
  <div
    id="step5"
    style="width: 100px; height: 20px"
    class="position-fixed bottom-0 right-0 me-5"
  ></div>
</template>
