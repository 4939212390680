<template>
  <v-card class="pa-4">
    <!-- Hauptinhalt (Timeline) links -->
    <v-card-text class="d-flex flex-column flex-md-row">
      <v-card
        flat
        class="overflow-auto w-100"
        :max-height="$vuetify.display.mdAndUp ? '80vh' : '50vh'"
      >
        <v-skeleton-loader
          v-for="i in 8"
          v-if="loading"
          :key="i"
          class="w-50 my-4 mx-auto text-center"
          type="list-item-avatar-three-line"
        ></v-skeleton-loader>
        <v-card-text class="overflow-auto" v-else>
          <v-timeline
            truncate-line="both"
            class="me-md-4"
            side="end"
            align="center"
          >
            <GKObjectTimelineItem
              v-for="(item, index) in sortedProgressData"
              :key="item.id"
              :item="item"
              :is-admin="isAdmin"
              :index="index"
              @updatedStep="getCurrentStep"
            />
          </v-timeline>
        </v-card-text>
      </v-card>
      <div
        class="text-center w-100 text-grey-darken-1 mt-md-16"
        v-if="!progressData && !loading"
      >
        Keine Prozessdaten vorhanden
      </div>

      <!-- Divider zwischen Timeline und Sidebar -->
      <v-divider
        class="ma-4"
        thickness="1"
        :vertical="$vuetify.display.mdAndUp"
      ></v-divider>

      <!-- Rechte Sidebar -->
      <v-card
        flat
        class="overflow-x-hidden text-text"
        :class="$vuetify.display.mdAndUp ? 'overflow-y-auto' : ''"
        min-width="300"
        :max-width="$vuetify.display.mdAndUp ? '380px' : '100%'"
        :max-height="$vuetify.display.mdAndUp ? '80vh' : ''"
      >
        <v-row>
          <v-col class="v-col-12">
            <v-list-item>
              <template #title>
                <div
                  style="font-size: 1.2rem"
                  class="d-flex justify-start align-center font-weight-bold mb-2 text-center text-md-left"
                >
                  Details
                  <v-dialog
                    v-if="isAdmin"
                    max-width="500px"
                    v-model="editAddress"
                  >
                    <template #activator="{ props }">
                      <v-btn
                        size="small"
                        variant="outlined"
                        class="mx-4"
                        v-bind="props"
                        >{{ $t("address.edit") }}</v-btn
                      >
                    </template>
                    <v-card>
                      <v-card-title>{{ $t("address.edit") }}</v-card-title>
                      <v-card-text>
                        <EditAddress
                          v-if="editAddress"
                          :object-id="object.id"
                          :address="object.address"
                        />
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </div>
              </template>
            </v-list-item>
            <div class="scrollable">
              <EditableListItem
                :label="object.title"
                v-model="object.objectType"
                :can-edit="isAdmin"
                type="select"
                :items="objectTypes"
                @update:modelValue="
                  (value) => updateObjectValue(value, 'objectType')
                "
              >
                <template #display>
                  {{ $t(`enum.objectType.${object.objectType}`) }}
                </template>
              </EditableListItem>

              <!-- <v-list
                color="transparent"
                rounded
                class="bg-transparent pa-0 my-1"
                max-height="100px"
              >
                <v-list-item
                  disabled
                  v-if="object.todoItemList.length <= 0"
                  :title="$t('noData.small', { obj: 'Aufgaben' })"
                ></v-list-item>
                <v-list-item
                  density="compact"
                  v-for="task in object.todoItemList"
                  :key="task.id"
                  @click="task.done = !task.done"
                >
                  <template v-slot:prepend>
                    <v-checkbox-btn
                      v-model="task.done"
                      color="grey"
                    ></v-checkbox-btn>
                  </template>
                  <v-list-item-title>
                    <span :class="task.done ? 'text-grey' : 'text-grey'">{{
                      task.text
                    }}</span>
                  </v-list-item-title>
                  <template v-slot:append>
                    <v-expand-x-transition>
                      <v-icon v-if="task.done" color="success">
                        mdi-check
                      </v-icon>
                    </v-expand-x-transition>
                  </template>
                </v-list-item>
                
              </v-list> -->
              <EditableListItem
                :label="$t('object.price')"
                v-model="object.value"
                :can-edit="isAdmin"
                @update:modelValue="
                  (value) => updateObjectValue(value, 'actualValue')
                "
              >
                <template #display>
                  <ReadableNumber :number="object.value" /> €
                </template>
              </EditableListItem>
              <EditableListItem
                :label="$t('object.expectedProvision')"
                v-model="object.expenctedRefferalFee"
                :can-edit="isAdmin"
                @click="isAdmin ? (showProvisionDialog = true) : null"
              >
                <template #display>
                  <v-chip density="compact" color="success"
                    ><ReadableNumber
                      :number="object.expenctedRefferalFee"
                      :number-format="ENumberFormat.CURRENCY"
                    />
                  </v-chip>
                </template>
              </EditableListItem>

              <v-dialog v-model="showProvisionDialog" max-width="500px">
                <v-card>
                  <v-card-text>
                    <v-text-field
                      :label="$t('object.provision')"
                      variant="solo-filled"
                      v-model="object.provision"
                      :readonly="!isAdmin"
                      :rules="[rules.percent]"
                    >
                      <template #append-inner>
                        <span class="text-disabled">%</span>
                      </template>
                    </v-text-field>
                    <v-text-field
                      :label="$t('object.expectedProvision')"
                      variant="solo-filled"
                      :rules="[rules.percent]"
                      v-model="object.expectedReferralFeePercentage"
                      :readonly="!isAdmin"
                    >
                      <template #append-inner>
                        <span class="text-disabled">%</span>
                      </template>
                    </v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn @click="showProvisionDialog = false">
                      {{ $t("common.close") }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" @click="updateExpectedRefferalFee">
                      {{ $t("common.save") }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <EditableListItem
                :label="$t('object.istFactor')"
                v-model="object.returnOfInvestment"
                :can-edit="isAdmin"
                @update:modelValue="
                  (value) => updateObjectValue(value, 'returnOfInvestment')
                "
              >
                <template #display>
                  <ReadableNumber :number="object.returnOfInvestment" />
                </template>
              </EditableListItem>
              <EditableListItem
                :label="$t('object.usableArea')"
                v-model="object.usableArea"
                :can-edit="isAdmin"
                @update:modelValue="
                  (value) => updateObjectValue(value, 'usableArea')
                "
              >
                <template #display>
                  <ReadableNumber :number="object.usableArea" />
                  <span class="ms-1" v-if="object.usableArea"> m²</span>
                </template>
              </EditableListItem>
              <EditableListItem
                :label="$t('object.baseArea')"
                v-model="object.landArea"
                :can-edit="isAdmin"
                @update:modelValue="
                  (value) => updateObjectValue(value, 'landArea')
                "
              >
                <template #display>
                  <ReadableNumber :number="object.landArea" />
                  <span class="ms-1" v-if="object.usableArea"> m²</span>
                </template>
              </EditableListItem>
              <EditableListItem
                :label="$t('object.yearOfConstruction')"
                v-model="object.constructionYear"
                :can-edit="isAdmin"
                @update:modelValue="
                  (value) => updateObjectValue(value, 'yearOfConstruction')
                "
              >
                <template #display>
                  <span v-if="!object.constructionYear">-</span>
                  <span v-else v-text="object.constructionYear"></span>
                </template>
              </EditableListItem>
              <v-list-item class="text-left" :title="$t('object.onlineSince')">
                <template #subtitle>
                  <DateDisplay :date="object.createdAt" />
                </template>
              </v-list-item>
            </div>
          </v-col>
          <v-col
            cols="12"
            @mouseenter="showImageEditIcon = true"
            @mouseleave="showImageEditIcon = false"
          >
            <v-list-item>
              <template #title>
                <div class="d-flex align-center mb-4">
                  <div
                    style="font-size: 1.1rem"
                    class="font-weight-bold text-center text-md-left"
                  >
                    {{ $t("object.pictures") }}
                  </div>
                  <v-btn
                    v-if="
                      isAdmin &&
                      (showImageEditIcon || $vuetify.display.mdAndDown)
                    "
                    density="compact"
                    class="ms-3 cursor-pointer"
                    icon="mdi-image-plus"
                    :color="$vuetify.display.mdAndDown ? 'grey' : 'grey'"
                    variant="plain"
                    @click="showUploadDialog = true"
                  ></v-btn>
                </div>
              </template>
            </v-list-item>

            <GKObjectImageCarousel
              :can-edit="isAdmin"
              :images="images"
              class="mb-4"
              @delete-image="deleteImage"
            />

            <!-- Bildupload Dialog -->
            <v-dialog v-model="showUploadDialog" width="600">
              <v-card>
                <v-card-title>{{ $t("common.uploadImages") }}</v-card-title>
                <v-card-text>
                  <add-images
                    :images="newImages"
                    @update-images="updateNewImages"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-btn @click="showUploadDialog = false">
                    {{ $t("common.cancel") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="accent"
                    @click="handleImageUpload"
                    :loading="uploading"
                  >
                    {{ $t("common.save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>

          <v-col class="v-col-12">
            <v-list-item>
              <template #title>
                <div
                  style="font-size: 1.1rem"
                  class="font-weight-bold mb-4 text-center text-md-left"
                >
                  {{ $t("object.contacts") }}
                </div>
              </template>
            </v-list-item>
            <EditableContactCard
              :contact="object.contact"
              :can-edit="isAdmin"
              :object-id="object.id"
              @update:contact="(newContact) => (object.contact = newContact)"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-card-text>
  </v-card>
</template>

<script>
import UserAvatar from "@/components/user/UserAvatar.vue";
import DateDisplay from "@/components/helpers/DateDisplay.vue";
import ReadableNumber from "@/components/helpers/ReadableNumber.vue";
import ApiService from "@/services/api.service";
import { useAuthUserStore } from "@/stores/modules/auth.module";
import GKObjectTimelineItem from "./GKObjectTimelineItem.vue";
import ENumberFormat from "@/enums/ENumberFormat";
import EditableListItem from "./EditableObjectDetail.vue";
import { useNotificationStore } from "@/stores/notification.module";
import NotificationElephant from "@/models/notification";
import GKObjectImageCarousel from "./GKObjectImageCarousel.vue";
import AddImages from "@/components/objects/create/AddImages.vue";
import EditableContactCard from "./EditableContactCard.vue";
import EditAddress from "@/components/objects/EditAddress.vue";

export default {
  name: "GKObjectItemDetails",
  components: {
    EditAddress,
    UserAvatar,
    DateDisplay,
    ReadableNumber,
    GKObjectTimelineItem,
    EditableListItem,
    GKObjectImageCarousel,
    AddImages,
    EditableContactCard,
  },
  setup() {
    const authUserStore = useAuthUserStore();
    const notificationStore = useNotificationStore();
    return { authUserStore, notificationStore };
  },
  props: {
    object: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ENumberFormat() {
      return ENumberFormat;
    },
    isAdmin() {
      return this.authUserStore.isAdmin;
    },
    sortedProgressData() {
      return [...this.progressData].sort((a, b) => a.stepIndex - b.stepIndex);
    },
    images() {
      return this.object?.images || [];
    },
  },
  data() {
    return {
      editAddress: false,
      progressData: null,
      loading: false,
      showProvisionDialog: false,
      objectTypes: [
        {
          title: this.$t("enum.objectType.TYPE_BAUERNHOF"),
          value: "TYPE_BAUERNHOF",
        },
        {
          title: this.$t("enum.objectType.TYPE_EINFAMILIENHAUS"),
          value: "TYPE_EINFAMILIENHAUS",
        },
        {
          title: this.$t("enum.objectType.TYPE_MEHRFAMILIENHAUS"),
          value: "TYPE_MEHRFAMILIENHAUS",
        },
        {
          title: this.$t("enum.objectType.TYPE_WOHNUNG"),
          value: "TYPE_WOHNUNG",
        },
        {
          title: this.$t("enum.objectType.TYPE_GEWERBEFLAECHE"),
          value: "TYPE_GEWERBEFLAECHE",
        },
        {
          title: this.$t("enum.objectType.TYPE_LAGERHALLE"),
          value: "TYPE_LAGERHALLE",
        },
        {
          title: this.$t("enum.objectType.TYPE_GRUNDSTUECK_WOHNEN"),
          value: "TYPE_GRUNDSTUECK_WOHNEN",
        },
        {
          title: this.$t("enum.objectType.TYPE_GEWERBE"),
          value: "TYPE_GEWERBE",
        },
        {
          title: this.$t("enum.objectType.TYPE_MIXED_USE"),
          value: "TYPE_MIXED_USE",
        },
        {
          title: this.$t("enum.objectType.TYPE_RESIDENTIAL_MFH"),
          value: "TYPE_RESIDENTIAL_MFH",
        },
        {
          title: this.$t("enum.objectType.TYPE_RESIDENTIAL_EFH"),
          value: "TYPE_RESIDENTIAL_EFH",
        },
        {
          title: this.$t("enum.objectType.TYPE_RESIDENTIAL_DHH"),
          value: "TYPE_RESIDENTIAL_DHH",
        },
        {
          title: this.$t("enum.objectType.TYPE_RESIDENTIAL_ETW"),
          value: "TYPE_RESIDENTIAL_ETW",
        },
        {
          title: this.$t(
            "enum.objectType.TYPE_RESIDENTIAL_WOHN_GESCHAEFTSHAUS"
          ),
          value: "TYPE_RESIDENTIAL_WOHN_GESCHAEFTSHAUS",
        },
        { title: this.$t("enum.objectType.TYPE_RETAIL"), value: "TYPE_RETAIL" },
        {
          title: this.$t("enum.objectType.TYPE_HOSPITALITY"),
          value: "TYPE_HOSPITALITY",
        },
        {
          title: this.$t("enum.objectType.TYPE_HEALTHCARE"),
          value: "TYPE_HEALTHCARE",
        },
        { title: this.$t("enum.objectType.TYPE_OFFICE"), value: "TYPE_OFFICE" },
        {
          title: this.$t("enum.objectType.TYPE_LOGISTIK"),
          value: "TYPE_LOGISTIK",
        },
        {
          title: this.$t("enum.objectType.TYPE_LIGHT_INDUSTRIAL"),
          value: "TYPE_LIGHT_INDUSTRIAL",
        },
        {
          title: this.$t("enum.objectType.TYPE_INFRASTRUCTURE"),
          value: "TYPE_INFRASTRUCTURE",
        },
        {
          title: this.$t("enum.objectType.TYPE_ENERGY_INFRASTRUCTURE"),
          value: "TYPE_ENERGY_INFRASTRUCTURE",
        },
      ],
      showUploadDialog: false,
      newImages: [],
      uploading: false,
      showImageEditIcon: false,
      rules: {
        percent: (v) => {
          return (
            (v >= 0 && v <= 100) || this.$t("common.messages.percentError")
          );
        },
      },
    };
  },
  methods: {
    updateExpectedRefferalFee() {
      this.updateObjectValue(this.object.provision, "provision");
      this.updateObjectValue(
        this.object.expectedReferralFeePercentage,
        "expectedRefferalFee"
      );
      this.showProvisionDialog = false;
      this.object.expenctedRefferalFee = Math.floor(
        this.object.value *
          (this.object.provision / 100) *
          (this.object.expectedReferralFeePercentage / 100)
      );
    },
    getCurrentStep() {
      let step = this.progressData
        .filter((item) => item.state === "IN_PROGRESS")
        .sort((a, b) => b.stepIndex - a.stepIndex)[0];
      this.$emit("updatedStep", step);
    },
    async fetchObjectProgress() {
      this.loading = true;
      try {
        const response = await ApiService.get(
          `gkProgress/object/${this.object.id}`
        );
        if (response && response.data) {
          this.progressData = response.data;
        }
      } catch (error) {
        this.notificationStore.push(
          new NotificationElephant({
            title: "Fehler",
            message: error.message,
            color: "ERROR",
          })
        );
      } finally {
        this.loading = false;
      }
    },
    async updateObjectValue(newValue, endpoint) {
      try {
        const response = await ApiService.put(
          `object/${this.object.id}/update/${endpoint}`,
          endpoint === "objectType" ? { string: newValue } : { value: newValue }
        );

        if (response && response.data) {
          this.object[endpoint] = response.data;

          this.notificationStore.push(
            new NotificationElephant({
              title: this.$t("common.messages.updateSuccess"),
              color: "SUCCESS",
            })
          );
        }
      } catch (error) {
        this.notificationStore.push(
          new NotificationElephant({
            title: this.$t("common.messages.updateError"),
            message: error.message,
            color: "ERROR",
          })
        );
      }
    },
    async handleImageUpload() {
      try {
        this.uploading = true;
        console.log("this.newImages", this.newImages);
        const response = await ApiService.put(
          `object/${this.object.id}/images/add`,
          this.newImages
        );

        if (!this.object.images) {
          this.$set(this.object, "images", []);
        }
        this.object.images = [...this.object.images, ...response.data];

        this.showUploadDialog = false;
        this.newImages = [];

        this.notificationStore.push(
          new NotificationElephant({
            title: this.$t("common.messages.imageUploadSuccess"),
            color: "SUCCESS",
          })
        );
      } catch (error) {
        this.notificationStore.push(
          new NotificationElephant({
            title: this.$t("common.messages.imageUploadError"),
            message: error.message,
            color: "ERROR",
          })
        );
      } finally {
        this.uploading = false;
      }
    },

    updateNewImages(images) {
      this.newImages = images;
    },

    async deleteImage(index) {
      try {
        const imageToDelete = this.images[index];
        console.log("imageToDelete", imageToDelete);
        const response = await ApiService.put(
          `object/${this.object.id}/images/${imageToDelete.id}/remove`
        );
        console.log("response", response);

        const newImages = [...this.object.images];
        newImages.splice(index, 1);
        this.object.images = newImages;

        this.notificationStore.push(
          new NotificationElephant({
            title: this.$t("common.messages.imageDeleteSuccess"),
            color: "SUCCESS",
          })
        );
      } catch (error) {
        this.notificationStore.push(
          new NotificationElephant({
            title: this.$t("common.messages.imageDeleteError"),
            message: error.message,
            color: "ERROR",
          })
        );
      }
    },
  },
  async created() {
    await this.fetchObjectProgress();
    console.log("object", this.object);
  },
};
</script>
