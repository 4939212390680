import { it as vit } from "vuetify/locale";

export const it = {
  $vuetify: {
    ...vit,
  },
  theme:{
    dark:"Modalità Scura",
    light:"Modalità Chiara",
  },
  refer: "Segnala una proprietà",
  referHint2: "In arrivo!",
  business: "Azienda",
  welcome: "Benvenuto su EstateBoost!",
  private: "Persona Privata",
  password: "Password",
  required: "Si prega di compilare questo campo",
  personalInformation: "Informazioni Personali",
  readMore: "Leggi di più",
  auth: {
    loginWithLinkedIn:"Accedi con LinkedIn",
    loginWithSocials: "Accedi con i Social",
    orUserNameAndPassword: "oppure Nome Utente e Password",
    alreadyHaveAccount: "Hai già un account? Accedi qui.",
    inputPassword: "Inserisci la password",
    repeatPassword: "Ripeti la password",
    password: {
      requirements: {
        title: "La password deve soddisfare i seguenti requisiti",
        oneNumber: "Contenere almeno un numero",
        oneSpecialChar: "Contenere almeno un carattere speciale",
        oneChar: "Contenere almeno una lettera maiuscola/minuscola",
        length: "Essere lunga almeno 8 caratteri"
      }
    },
    forgotPassword: "Password dimenticata?",
    createAccount: "Crea Account",
    register: {
      title: "Registrati",
      privacy: "Ho letto e accettato i {agb} & {privacy}.",
      privacyError: "Per registrarti, devi accettare i termini e la privacy policy.",
      business: {
        checkbox: "Sono soggetto a IVA",
        name: "Nome dell'azienda",
        legalForm: "Forma giuridica",
        uStIdNr: "Partita IVA",
        hint: "Chi è soggetto a IVA?",
        hintText: "In Germania, le imprese con un fatturato annuo superiore a €22.000 devono pagare l'IVA. Al di sotto di questa soglia, si qualificano come piccole imprese."
      },
      businessOrPrivate: "Ti stai registrando come azienda o come individuo?",
      businessOrPrivateHint: "EstateBoost offre funzionalità personalizzate per aziende e individui per soddisfare le tue esigenze specifiche."
    }
  },
  points: "Punti",
  rank: "Classifica",
  profile: {
    share: {
      text:
        "Questo è il tuo link personale al profilo. È protetto da una chiave generata casualmente, quindi solo le persone che hanno questo link possono accedere al tuo profilo.\n" +
        "\n" +
        "    Cliccando su rigenera, il vecchio link diventerà non valido.",
      generate: "Rigenera",
      share: "Condividi Profilo",
      sharetext: "Visita il mio profilo su EstateBoost.",
    },
  },
  achievements: {
    empty: "Nessun altro risultato",
  },
  rewards: {
    unclaimed: "Non richiesto",
    claimed: "Richiesto",
  },
  dashboard: {
    your_properties: "Le tue Proprietà",
    ranking: "Classifica",
    education: "Formazione",
    to_wallet: "Al Portafoglio",
    compareValue: "Valore di confronto sempre il 1° del mese",
  },
  dailyreward: {
    button: "Bonus Giornaliero",
    hint: "Puoi richiederlo solo una volta al giorno.",
    claim: "Richiedi",
    thanks: "Grazie",
    schade: "Peccato",
  },
  time: {
    days: "Giorni",
    hours: "Ore",
    minutes: "Minuti",
    seconds: "Secondi",
  },
  confirm_email: {
    text: "La tua email non è ancora confermata.",
    button: "Conferma Ora",
  },
  enum: {
    pending: "In attesa",
    gkProcess: {
      SEND_TEASER_EXPOSE: "Invia Teaser/Esposizione",
      AWAIT_APPROVAL: "In attesa di approvazione",
      ADD_PROPERTY_IN_ESTATEBOOST: "Aggiungi proprietà in EstateBoost",
      VERIFY_INPUT: "Verifica input",
      CREATE_EXPOSE_DATA_ROOM: "Crea Data Room esposizione",
      EVALUATE_PROPERTY: "Valuta proprietà",
      APPROVE_EVALUATION: "Approva valutazione",
      OPEN_DUE_DILIGENCE_PROCESS: "Apri processo Due Diligence",
      INITIATE_MARKET_CONTACT: "Avvia contatto di mercato",
      MAKE_BINDING_OFFER: "Fai offerta vincolante",
      COMPLETE_NOTARIZATION: "Completa autenticazione notarile",
      RECEIVE_EXTERNAL_COMMISSION: "Ricevi commissione esterna",
      PAY_TIP_PROVIDER_COMMISSION: "Paga commissione segnalatore",
      ANALYZE_PROPERTY_DATA: "Analizza dati proprietà",
      CONDUCT_MARKET_ANALYSIS: "Conduci analisi di mercato",
      SELECT_EVALUATION_METHOD: "Seleziona metodo di valutazione",
      INTERNAL_VALIDATION: "Validazione interna",
      APPROVE_COMMUNICATION: "Approva comunicazione",
      PREPARE_NEGOTIATION_STRATEGY: "Prepara strategia di negoziazione",
      START_NEGOTIATIONS: "Avvia negoziazioni",
      CONDUCT_NEGOTIATIONS: "Conduci negoziazioni",
      ADJUST_CONTRACT_TERMS: "Modifica termini contrattuali",
      FINALIZE_AND_SIGN_CONTRACT: "Finalizza e firma contratto",
    },
    gkProcessState: {
      OPEN: "Aperto",
      IN_PROGRESS: "In Corso",
      DONE: "Completato",
    },
    objectType: {
      TYPE_BAUERNHOF: "Fattoria",
      TYPE_EINFAMILIENHAUS: "Casa Singola",
      TYPE_MEHRFAMILIENHAUS: "Casa Multi-Famiglia",
      TYPE_WOHNUNG: "Appartamento",
      TYPE_GEWERBEFLAECHE: "Area Commerciale",
      TYPE_LAGERHALLE: "Magazzino",
      TYPE_GRUNDSTUECK_WOHNEN: "Terreno Residenziale",
      TYPE_GEWERBE: "Azienda",
      TYPE_MIXED_USE: "Uso Misto",
      TYPE_RESIDENTIAL_MFH: "Residenziale Multi-Famiglia",
      TYPE_RESIDENTIAL_EFH: "Residenziale Mono-Famiglia",
      TYPE_RESIDENTIAL_DHH: "Casa Bifamiliare",
      TYPE_RESIDENTIAL_ETW: "Appartamento di Proprietà",
      TYPE_RESIDENTIAL_WOHN_GESCHAEFTSHAUS: "Edificio Residenziale-Commerciale",
      TYPE_RETAIL: "Vendita al Dettaglio",
      TYPE_HOSPITALITY: "Ospitalità",
      TYPE_HEALTHCARE: "Sanità",
      TYPE_OFFICE: "Ufficio",
      TYPE_LOGISTIK: "Logistica",
      TYPE_LIGHT_INDUSTRIAL: "Industria Leggera",
      TYPE_INFRASTRUCTURE: "Infrastruttura",
      TYPE_ENERGY_INFRASTRUCTURE: "Infrastruttura Energetica",
    },
  },
  tasks: "Compito | Compiti",
  notification: {
    title: "Notifiche",
    deleteAll: "Elimina Tutto",
    noData: "Nessuna Notifica",
  },
  wallet: {
    token: "Token | Token",
    coin: "Moneta | Monete",
  },
  education: {
    to: "Per l'Istruzione",
  },
  files: {
    download: "Scarica File",
    upload: "Carica Nuovo File",
  },
  process: {
    coinFormula:
      "Questo valore rappresenta la tua ricompensa per aver completato un contratto di mediazione immobiliare tramite il tuo suggerimento. È calcolato sulla base della commissione dell'agente immobiliare, che dipende dal prezzo di vendita. La tua quota della commissione (commissione interna) è pari a",
    ownerConfirmation: {
      title: "Consenso del Proprietario",
      text: "Il consenso del proprietario è l'elemento chiave del processo.<br/>EstateBoost si impegna per la <b>trasparenza</b> e l'<b>onestà</b> e raccoglie solo dati forniti <b>volontariamente</b> e con <b>intenzione esplicita da parte del proprietario</b>.",
      alert:
        "Ribadiamo che non siamo interessati a dati che non possono essere trasferiti legalmente.*",
      footNote: "*Qualsiasi abuso comporterà la sospensione dell'account.",
      confirm:
        "Confermo di avere il consenso del proprietario dell'immobile per inserire i suoi dati personali sulla nostra piattaforma.",
      download: "Scarica Modello PDF",
      upload: "Carica Nuovo Consenso",
      noConfirmation: "Nessun consenso caricato",
      noConfirmation2: "Nessun consenso fornito.",
    },
    objectData: {
      title: "Inserisci Dati dell'Immobile",
    },
    verification: {
      title: "Verifica",
      text: "Il tuo immobile è pronto per la verifica.<br/>Controlla attentamente i tuoi dati prima di inviarli.",
      warning:
        "Una volta avviata la verifica, i dati non saranno più modificabili.",
      button: "Verifica Ora",
      running:
        '<p>La verifica è in corso. Ti avviseremo quando il proprietario sarà stato contattato e i tuoi dati verificati.</p><p>Stato attuale:<br/> <small class="text-accent" v-html="state.message"></small></p>',
    },
    contract: {
      title: "Contratto di Mediazione Concluso",
      text: 'Il contratto di mediazione con il proprietario è <span class="success">attivo</span>.<br/>Abbiamo tempo fino al <v-chip >{date}</v-chip> per vendere la tua proprietà.',
    },
    marketing: {
      title: "Marketing",
    },
    notaryAppointment: {
      title: "Appuntamento Notarile",
    },
    provision: {
      title: "Commissione Ricevuta",
    },
    collect: {
      title: "Raccogli Monete",
      text: "Puoi ora raccogliere le tue monete! Inoltre, puoi convertirle in euro e trasferirle sul tuo conto.",
      alreadyCollected: "Hai già raccolto le tue monete.",
    },
    overallProgress: "Progresso Complessivo",
    collectCoins: "Raccogli Monete Ora",
    success: "Successo",
    reward: "Guadagno Monete",
  },
  topBar: {
    saveToHomescreen: "Salva l'App Web nella schermata Home!",
    apple:
      'iPhone/iPad: Tocca il pulsante Condividi e scegli "Aggiungi a Schermata Home".',
    android: 'Tocca i tre punti e scegli "Aggiungi a Schermata Iniziale".',
  },
  greeting: {
    morning: "Buongiorno",
    lunch: "Buon Pranzo",
    evening: "Buonasera",
    default: "Buona Giornata",
  },
  buttons: {
    buy: "Compra",
    close: "Chiudi",
    save: "Salva",
    send: "Invia",
    continue: "Continua",
    cancel: "Annulla",
    open: "Apri",
  },
  bankDetails: {
    IBAN: "IBAN",
    BIC: "BIC",
    bankName: "Nome Banca",
    accountHolder: "Titolare del Conto",
  },
  user: {
    level: "Livello",
    profile: {
      tipster: "Suggeritore",
      checkEducation:
        "*Per aggiungere un immobile, è necessario completare la formazione sulla privacy.",
    },
    registeredAt: "Membro dal",
    firstName: "Nome",
    lastName: "Cognome",
    email: "Email",
    phone: "Numero di Telefono",
    mobile: "Cellulare",
    bio: "Biografia",
    birthDate: "Data di Nascita",
    messages: {
      emailVerified: "Il tuo indirizzo email è verificato.",
      emailNotVerified:
        "Per utilizzare appieno l'app, devi verificare la tua email. <br/>Clicca su <b>Verifica Email Ora</b> e ti invieremo un'email.",
      validateBtn: "Verifica Email Ora.",
      changeBirthDate: "Aggiorna Data di Nascita",
    },
  },
  address: {
    title: "Indirizzi",
    showOnMap: "Mostra sulla Mappa",
    save: "Aggiungi Nuovo Indirizzo",
    street: "Via",
    houseNumber: "Numero Civico",
    zip: "CAP",
    city: "Città",
    bundesland: "Provincia",
    country: "Paese",
  },
  yourObjects: "Le tue Proprietà",
  allObjects: "Tutte le Proprietà",
  form: {
    search: "Cerca",
    invalidDate: "Data Non Valida",
  },
  noData: {
    default: "Nessun Dato Trovato",
    reactive: "Nessun {obj} trovato",
    small: "Nessun {obj}",
  },
  object: {
    owner: {
      title: "Proprietario dell'Immobile",
      fullName: {
        title: "Nome Completo del Proprietario",
        hint: "Ricorda che non è consentito fornire questi dati senza il consenso del proprietario.",
      },
      email: "Email del Proprietario",
      telephone: "Telefono del Proprietario",
      error: {
        importantData: "Mancano dati importanti!",
        nameMissing: "Il nome del proprietario è mancante.",
        emailMissing: "L'email del proprietario è mancante.",
        phoneMissing: "Il numero di telefono del proprietario è mancante.",
      },
    },
    sale: "VENDITA",
    retirement: "PENSIONAMENTO",
    slots: {
      locked: "Slot Bloccato",
      unlock: "Sblocca Slot",
      buy: {
        confirm:
          "Confermi che {token} token verranno detratti e aggiunto uno slot?",
        title: "Acquista un nuovo slot per aggiungere altre proprietà.",
        insufficientSales:
          "Devi aver venduto almeno 2 proprietà per sbloccare nuovi slot.",
        commercialActivity:
          "Dalla terza proprietà annuale si considera un'attività commerciale. Considera di aprire un'attività.",
        priceCalculation:
          "Il prezzo è calcolato sui tuoi suggerimenti più recenti.",
      },
    },
    saled: "venduto",
    object: "Oggetto | Oggetti",
    estate: "Proprietà | Proprietà",
    price: "Prezzo",
    istFactor: "Fattore Attuale / Rendimento Attuale",
    usableArea: "Area Utile",
    flatArea: "Superficie Abitabile",
    baseArea: "Area Base",
    tooltip: "Commissione stimata dell'intermediario",
    yearOfConstruction: "Anno di Costruzione",
    onlineSince: "Online da",
    contacts: "Contatti",
    selectContact: "Seleziona Contatto",
    pictures: "Immagini",
    addNote: "Aggiungi nota...",
    noNote: "Nessuna nota",
    add: "Aggiungi Proprietà",
    expected: "stimato",
    actual: "effettivo",
    provision: "Commissione",
    expectedProvision: "Commissione Attesa Intermediario",
    fairValue: "Valore di Mercato",
    fairValueExpected: "Valore di Mercato Stimato",
    fairValueExpectedExplanation:
      "Il valore stimato è basato su diversi fattori e può variare dal prezzo di vendita.",
  },
  settingsPopup: {
    admin: {
      name: "Admin",
      title: "Area Admin",
      objects: {
        title: "Proprietà",
        subtitle: "Gestisci le tue proprietà",
        verification: {
          title: "Verifica",
          whyError: "Perché la verifica è fallita?",
          whyErrorPlaceholder: "Motivo qui...",
          whyErrorLabel: "Motivo del Fallimento",
          send: "Invia",
          cancel: "Annulla",
          declarationOfConsent: "Dichiarazione di Consenso del Proprietario",
          sellType: "Interesse in",
          objectType: "Tipo di Proprietà",
          livingArea: "Superficie Abitabile",
          baseArea: "Area Terreno",
          owner: "Proprietario",
          createdBy: "Creato da: ",
        },
        marketing: {
          title: "Marketing",
          edit: "Modifica",
          view: "Visualizza",
        },
        success: "Successo",
        error: "Errore",
        edit: {
          brokerCommissionGk: {
            title: "Commissione Broker GK",
            fairValue: "Valore di Mercato Pre-Contratto",
            salesValue: "Valore di Vendita",
            provision: "Commissione in %",
            tipgiverProvision: "Commissione Tipster in %",
            brokerCommission: "Commissione Broker in %",
            closeBtn: "Chiudi",
          },
          userView: "Vista Utente",
          overview: {
            title: "Panoramica",
          },
          agentContract: {
            title: "Contratto Broker",
            dateEnd: "Data Fine Contratto",
            signedCheckbox: "Contratto Firmato",
            loadingBtn: "Crea Oggetto Contratto",
            saveBtn: "Salva",
          },
          marketing: {
            title: "Marketing",
            date: "Appuntamento Notarile il: ",
            successCheckbox: "Proprietà Commercializzata",
            saveBtn: "Salva",
            loadingBtn: "Crea Oggetto Appuntamento Notarile",
          },
          payment: {
            title: "Pagamento",
            saveBtn: "Completa Proprietà per Utente",
            infoText1: "*L'utente può ora raccogliere i suoi coins",
            infoText2: "L'utente può ora ritirare i suoi coins",
          },
          fairValue: "Valore di Mercato",
          provision: "Commissione",
          tipGiver: "Tipster",
        },
        provision: "Commissione",
        expectedProvision: "Commissione stimata",
        pictures: "Immagini",
      },
      trainingCenter: {
        title: "Centro di Formazione",
        subtitle: "Modifica e Aggiungi Formazioni",
        courseSection: {
          noData: "Nessuna sezione trovata",
          addBtn: "Aggiungi",
          addTitle: "Titolo",
          addDescription: "Descrizione",
          addChapter: "Capitolo",
          addSaveBtn: "Carica Nuovo Corso",
        },
      },
      payout: {
        title: "Pagamenti",
        subtitle: "Tutti i Pagamenti",
      },
      statistics: {
        title: "Statistiche",
        subtitle: "Statistiche",
      },
    },
    gkAdmin: {
      name: "Gestione Clienti Commerciali",
      details: {},
    },
    accountDetails: {
      name: "Dettagli Account",
      details: {},
    },
    address: {
      name: "Indirizzi",
      details: {},
    },
    bankDetails: {
      name: "Dettagli Bancari",
      details: {},
    },
    shareProfile: {
      name: "Condividi Profilo",
      details: {},
    },
    settings: {
      name: "Impostazioni",
      details: {},
    },
    changePassword: {
      name: "Cambia Password",
      details: {
        newPassword: "Nuova Password",
        confirmPassword: "Conferma Password",
        oldPassword: "Password Attuale",
        change: "Cambia Password",
        validation: {
          title: "La password deve soddisfare i seguenti requisiti:",
          length: "Almeno 8 caratteri",
          number: "Almeno un numero",
          specialChar: "Almeno un carattere speciale",
          char: "Almeno una lettera maiuscola/minuscola",
          match: "Le password non corrispondono",
          required: "Campo Obbligatorio",
        },
      },
    },
    achievements: {
      name: "Risultati",
      details: {},
    },
    trainingCenter: {
      name: "Centro di Formazione",
      details: {},
    },
    docTemplates: {
      name: "Modelli di Documenti",
      details: {},
    },
    logout: "Disconnetti",
  },
  filter: {
    headline: "Filtra",
    deleteAll: "Elimina Tutti i Filtri",
    sort: {
      by: "Ordina per",
      price: {
        desc: "Prezzo più alto",
        asc: "Prezzo più basso",
      },
      area: {
        desc: "Area Terreno Maggiore",
        asc: "Area Terreno Minore",
      },
      usableArea: {
        desc: "Area Utile Maggiore",
        asc: "Area Utile Minore",
      },
      yearOfConstruction: {
        desc: "Anno più recente",
        asc: "Anno più vecchio",
      },
      returnOfInvestment: {
        desc: "ROI più alto",
        asc: "ROI più basso",
      },
      onlineSince: {
        desc: "Inserzione più recente",
        asc: "Inserzione più vecchia",
      },
    },
    min: "Min",
    max: "Max",
    found: {
      init:"nessun oggetto ancora",
      object: "Oggetto trovato | Oggetti trovati",
    },
    geschaeftskunden: "Clienti Commerciali",
  },
  common: {
    uploadImages: "Carica Immagini",
    save: "Salva",
    cancel: "Annulla",
    close: "Chiudi",
    images: "Immagini",
    messages: {
      percentError: "Il valore deve essere compreso tra 0 e 100",
      imageUploadSuccess: "Immagini caricate con successo",
      imageUploadError: "Errore durante il caricamento delle immagini",
      imageDeleteSuccess: "Immagine eliminata con successo",
      imageDeleteError: "Errore durante l'eliminazione dell'immagine",
      updateSuccess: "Aggiornamento riuscito",
      updateError: "Errore durante l'aggiornamento",
    },
  },
  tour: {
    startTooltip: "Tour inizia",
    next: "Avanti",
    prev: "Indietro",
    done: "Fine",
    steps: {
      objectOverview: {
        title: "Panoramica Oggetti",
        intro:
          "Qui ci sono tutti gli affari segnalati che stiamo attualmente rappresentando sul mercato per te. Puoi vedere la tua commissione prevista in verde e lo stato attuale in arancione. Puoi cliccare su uno qualsiasi di essi per vedere i dettagli del potenziale affare.",
      },
      search: {
        title: "Ricerca",
        intro:
          "Puoi cercare parole chiave per restringere i tuoi oggetti o trovarne uno specifico.",
      },
      filter: {
        title: "Filtro",
        intro: "Puoi anche filtrare i risultati...",
      },
      sort: {
        title: "Ordinamento",
        intro: "e ordinarli secondo le tue preferenze.",
      },
      feedback: {
        title: "Feedback",
        intro:
          "Il tuo feedback sincero è importante per noi. Per favore, facci sapere come possiamo migliorare EstateBoost.",
      },
    },
  },
};
